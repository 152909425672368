import { createLifecycleFor } from 'ext/lib/collections';

export const TYPE = 'stepChildren';

export const {
  cancel,
  add,
  create,
  insert,
  update,
  reject,
  replace,
  prune,
  flush,
  remove,
  focus,
  patterns,
} = createLifecycleFor(TYPE);

export const STEP_CHILD_CLONED = 'STEP_CHILD_CLONED';
export const clone = id => ({
  type: STEP_CHILD_CLONED,
  payload: { id },
});
