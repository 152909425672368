import React from 'react';
import { QRCodeSVG } from 'qrcode.react';
import PropTypes from 'prop-types';
import { Button } from '@appcues/sonar';
import { InputClickToCopy } from 'ext/components/ui';
import { Form, FormGroup, Label } from 'components/Form';
import { QRCodeWrapper } from './styled';

const PreviewQRCode = ({ previewUrl, onClick }) => (
  <>
    <Form aria-label="Preview mobile flow" role="form">
      <FormGroup>
        <Label>Scan this QR Code:</Label>
        <QRCodeWrapper>
          <QRCodeSVG
            aria-label="Preview mobile URL QR code"
            size={225}
            value={previewUrl}
          />
        </QRCodeWrapper>
      </FormGroup>
      <FormGroup>
        <Label>Or open this URL on your device:</Label>
        <InputClickToCopy text={previewUrl} wrap />
      </FormGroup>
    </Form>
    <Button kind="primary" onClick={onClick} fluid>
      Done
    </Button>
  </>
);

PreviewQRCode.propTypes = {
  previewUrl: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default PreviewQRCode;
