/* eslint-disable unicorn/consistent-function-scoping */
/* eslint-disable no-use-before-define */
import xliff from 'xliff';
import {
  BLOCK,
  LOCALIZED,
  TEXT,
  IMAGE,
  LABEL,
  ERROR_LABEL,
  TEXT_INPUT,
  OPTION_SELECT,
  PLACEHOLDER,
} from 'lib/block';
import { wrapBlocksWithLocalized } from './wrap-localized';

/**
 *
 * @param {object} xliffContents - translations coming from the XLIFF file
 * @param {object} experienceJSON - canonical experience JSON
 * @param {string} targetLocale - selected locale to add translations for
 * @return {object} - canonical experience with the translations added
 */
export const addTranslations = async (
  xliffContents,
  experienceJSON,
  targetLocale
) => {
  const parsedXLIFF = await xliff.xliff12ToJs(xliffContents);
  const experienceTranslation = parsedXLIFF.resources[experienceJSON.id];

  // if there are no translations or malformed data, return the original experience
  if (experienceTranslation === undefined) return experienceJSON;

  // First we wrap the blocks with localized and empty variations to be filled
  const newExperienceJSON = wrapBlocksWithLocalized({ ...experienceJSON });

  const handleVariations = (node, translations) => {
    const updatedNode = {
      ...node,
      variations: {
        ...node.variations,
        [targetLocale]: node.variations[targetLocale] ?? {
          ...node.content,
        },
      },
    };

    updatedNode.variations[targetLocale] = updatedWithTranslation(
      updatedNode.variations[targetLocale],
      translations
    );

    return updatedNode;
  };

  const handleTextInput = (node, translations) => {
    const updatedNode = { ...node };
    [LABEL, ERROR_LABEL, PLACEHOLDER].forEach(key => {
      updatedNode[key] = updatedWithTranslation(updatedNode[key], translations);
    });
    return updatedNode;
  };

  const handleOptionSelect = (node, translations) => {
    const updatedNode = { ...node };
    [LABEL, ERROR_LABEL].forEach(key => {
      updatedNode[key] = updatedWithTranslation(updatedNode[key], translations);
    });

    updatedNode.options = updatedNode.options.map(option => {
      const newOption = { ...option };
      newOption.content = updatedWithTranslation(
        newOption.content,
        translations
      );
      if (newOption.selectedContent !== undefined) {
        newOption.selectedContent = updatedWithTranslation(
          newOption.selectedContent,
          translations
        );
      }
      return newOption;
    });
    return updatedNode;
  };

  const handlePrimitiveNodes = (node, translations) => {
    const updatedNode = { ...node };

    if (updatedNode.type === TEXT) {
      updatedNode.text = translations[updatedNode.id].target;
      delete updatedNode.spans;
    }

    if (updatedNode.type === IMAGE) {
      updatedNode.accessibilityLabel = translations[updatedNode.id].target;
    }

    return updatedNode;
  };

  const updatedWithTranslation = (node, translations) => {
    if (node.type === BLOCK && node.blockType === LOCALIZED) {
      return handleVariations(node, translations);
    }

    if (node.items !== undefined) {
      return {
        ...node,
        items: node.items.map(item =>
          updatedWithTranslation(item, translations)
        ),
      };
    }

    if (node.content !== undefined) {
      return {
        ...node,
        content: updatedWithTranslation(node.content, translations),
      };
    }

    if (node.type === TEXT_INPUT) {
      return handleTextInput(node, translations);
    }

    if (node.type === OPTION_SELECT) {
      return handleOptionSelect(node, translations);
    }

    if (translations[node.id] !== undefined) {
      return handlePrimitiveNodes(node, translations);
    }

    return node;
  };

  newExperienceJSON.steps = newExperienceJSON.steps.map(stepGroup => {
    const newStepGroup = { ...stepGroup };

    newStepGroup.children = newStepGroup.children.map(stepChild => {
      const translations = experienceTranslation?.[stepChild.id]?.groupUnits;
      if (translations !== undefined) {
        return updatedWithTranslation(stepChild, translations);
      }
      return stepChild;
    });

    return newStepGroup;
  });

  newExperienceJSON.containsLocalizedBlocks = true;

  return newExperienceJSON;
};
