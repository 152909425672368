import { v4 as uuid } from 'uuid';
import { getContinue } from 'lib/actions';
import { BLOCK, TEXT, BUTTON, STACK, IMAGE, IMAGE_WITH_TEXT } from 'lib/block';
import generateElementTemplate, {
  generateRow,
} from 'components/Editor/Templates/generate-element-template';

/**
 * Create canned embed CTA step
 *
 * @param {string} previewStepId - id to preview the step template before creation
 * @return {Step} Canned step group
 */
export const generateEmbedCTAStep = ({ previewStepId }) => {
  const buttonElementId = uuid();

  return {
    ...(previewStepId && { id: previewStepId }),
    type: 'embed',
    contentType: 'application/json',
    content: {
      id: uuid(),
      type: STACK,
      orientation: 'vertical',
      style: {},
      items: [
        generateRow([
          {
            blockType: IMAGE_WITH_TEXT,
            id: uuid(),
            type: BLOCK,
            content: {
              type: STACK,
              id: uuid(),
              distribution: 'center',
              orientation: 'horizontal',
              style: {
                horizontalAlignment: 'leading',
                marginLeading: 0,
                marginTop: 8,
                marginTrailing: 8,
                paddingBottom: 8,
                paddingLeading: 16,
                paddingTop: 8,
                paddingTrailing: 16,
                verticalAlignment: 'top',
              },
              items: [
                {
                  type: IMAGE,
                  blurHash: '',
                  contentMode: 'fit',
                  id: uuid(),
                  imageUrl:
                    'https://res.cloudinary.com/dnjrorsut/image/upload/v1692992744/106160/mtgk6clvoxhkemneflv4.png',
                  intrinsicSize: {
                    height: 192,
                    width: 240,
                  },
                  style: {
                    marginTrailing: 16,
                    verticalAlignment: 'top',
                    width: 64,
                  },
                },
                {
                  type: STACK,
                  id: uuid(),
                  orientation: 'vertical',
                  style: {
                    horizontalAlignment: 'leading',
                  },
                  items: [
                    generateElementTemplate({
                      template: TEXT,
                      blockId: uuid(),
                      elementId: uuid(),
                      content: {
                        text: "It's more fun with friends!",
                        style: {
                          fontSize: 18,
                          fontName: 'System Default Semibold',
                          horizontalAlignment: 'leading',
                          textAlignment: 'leading',
                          foregroundColor: { light: '#FFFFFF' },
                          marginTop: 0,
                          marginLeading: 0,
                          marginTrailing: 0,
                        },
                      },
                    }),
                    generateElementTemplate({
                      template: TEXT,
                      blockId: uuid(),
                      elementId: uuid(),
                      content: {
                        text: 'Invite your friends to the app to begin sharing content.',
                        style: {
                          fontSize: 16,
                          horizontalAlignment: 'leading',
                          textAlignment: 'leading',
                          foregroundColor: { light: '#FFFFFF' },
                          marginTop: 0,
                          marginBottom: 0,
                          marginLeading: 0,
                          marginTrailing: 0,
                        },
                      },
                    }),
                  ],
                },
              ],
            },
          },
        ]),
        generateElementTemplate({
          template: BUTTON,
          blockId: uuid(),
          elementId: buttonElementId,
          newRow: true,
          content: {
            text: 'Invite now',
            style: {
              fontSize: 17,
              fontName: 'System Default Bold',
              foregroundColor: { light: '#165196' },
            },
          },
          style: {
            horizontalAlignment: 'trailing',
            marginBottom: 8,
            paddingBottom: 8,
            paddingLeading: 16,
            paddingTop: 8,
            paddingTrailing: 16,
            cornerRadius: 26,
            backgroundColor: { light: '#FFFFFF' },
          },
        }),
      ],
    },
    actions: {
      [buttonElementId]: [getContinue()],
    },
  };
};
