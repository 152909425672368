import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import debounce from 'lodash.debounce';
import { Button, InlineMessage, Row, Slider } from '@appcues/sonar';
import {
  Accordion,
  FieldSet,
  FontIcon,
  Label,
  Switch,
} from 'ext/components/ui';
import { EditorShape, effectsTemplate } from 'entities/step-groups';
import { selectPreviewType, updatePreview } from 'entities/user-interface';
import { CONFETTI, TRAIT_LABELS } from 'lib/trait';
import ColorInput from 'components/ColorInput';
import InputWithValidation from 'components/InputWithValidation';
import useAccordionClick from './use-accordion-click';
import HelpLabel from './HelpLabel';
import { Controls, GroupedField } from './styled';

export function Effects({
  editor,
  stepGroup,
  onChange,
  selectPreview,
  onPreviewUpdate,
}) {
  const trackAccordion = useAccordionClick();
  const { effects, type } = editor || {};
  const {
    presentationStyle: presentation,
    duration,
    intensity = 1,
    style,
  } = effects || {};
  const [intensityValue, setIntensityValue] = useState(intensity);

  const { colors } = style || {};

  const { show } = selectPreview(stepGroup, presentation);

  const traitLabel = TRAIT_LABELS[type];

  const handleConfettiEffectShowChange = checked => {
    if (checked) {
      onChange({ effects: effectsTemplate(CONFETTI).config });
    } else {
      onChange(null, 'effects');
    }
  };

  const handleColorChange = (index, value) => {
    const updatedColors = colors.map((color, i) =>
      i === index ? value : color
    );

    onChange({
      effects: { ...effects, style: { ...style, colors: updatedColors } },
    });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleBehaviorChange = useCallback(
    debounce((key, value) => {
      const handledValue =
        Number.parseInt(value, 10) / (key === 'intensity' ? 10 : 1);

      onChange({
        effects: {
          ...effects,
          [key]: handledValue,
        },
      });
    }, 500),
    [effects, onChange]
  );

  return (
    <Accordion.Item value="effects">
      <Accordion.Header>
        <Accordion.Trigger
          onClick={() => trackAccordion(traitLabel, 'Effects')}
        >
          <Row yAlign="center" spacing="none">
            Effects
            <HelpLabel>Requires SDK versions ≥ 3.3.0</HelpLabel>
          </Row>
          <FontIcon size="sm" icon="chevron-down" />
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content>
        <Controls>
          <FieldSet>
            <Switch
              label="Confetti"
              checked={!!effects}
              onChange={handleConfettiEffectShowChange}
            />
          </FieldSet>

          {presentation === CONFETTI && (
            <>
              {colors.map((color, index) => {
                const id = `color-${index + 1}`;

                return (
                  <FieldSet key={id}>
                    <GroupedField>
                      <Label htmlFor={id}>Color {index + 1}</Label>
                      <ColorInput
                        id={id}
                        color={color}
                        onChange={value => handleColorChange(index, value)}
                      />
                    </GroupedField>
                  </FieldSet>
                );
              })}

              <FieldSet>
                <Label htmlFor="duration">Duration</Label>
                <InputWithValidation
                  id="Duration"
                  value={duration}
                  onChange={({ target }) =>
                    handleBehaviorChange('duration', target.value)
                  }
                  type="number"
                  min="1000"
                  max="10000"
                  suffix="ms"
                />
              </FieldSet>

              <FieldSet>
                <Label htmlFor="intensity">Intensity</Label>
                <Slider
                  id="intensity"
                  value={[intensityValue * 10]}
                  onValueChange={values => {
                    setIntensityValue(values[0] / 10);
                    handleBehaviorChange('intensity', values[0]);
                  }}
                  min={5}
                  max={15}
                  step={5}
                />
                <InlineMessage>
                  The overall amount of confetti released
                </InlineMessage>
              </FieldSet>

              <FieldSet>
                <Button
                  aria-label={`Preview ${presentation} effect`}
                  aria-pressed={show}
                  variant="secondary"
                  fluid
                  onClick={() =>
                    onPreviewUpdate({
                      blockId: stepGroup,
                      previewType: CONFETTI,
                      show: !show,
                    })
                  }
                >
                  <FontIcon icon="eye" />
                  Preview
                </Button>
              </FieldSet>
            </>
          )}
        </Controls>
      </Accordion.Content>
    </Accordion.Item>
  );
}

Effects.propTypes = {
  editor: EditorShape,
  stepGroup: PropTypes.string,
  onChange: PropTypes.func,
  selectPreview: PropTypes.func,
  onPreviewUpdate: PropTypes.func,
};

const mapStateToProps = state => {
  const selectPreview = (blockId, previewType) =>
    selectPreviewType(state, { blockId, previewType });

  return {
    selectPreview,
  };
};

const mapDispatchToProps = {
  onPreviewUpdate: updatePreview,
};

export default connect(mapStateToProps, mapDispatchToProps)(Effects);
