/* eslint-disable no-param-reassign */
/* eslint-disable no-use-before-define */
import xliff from 'xliff';
import { BLOCK, LOCALIZED, LABEL, ERROR_LABEL, PLACEHOLDER } from 'lib/block';

/**
 * Provides handlers based on the block type
 *
 * @param {Node} node - Experience node or content block
 * @param {object} variantNode - node or content block inside variations
 * @param {object} dictionary - object that will contain source/target localized texts
 * @param {string} targetLocaleId - Locale ID
 * @return {object} - Handlers object containing each type handler
 */
function handleTypes(node, variantNode, dictionary, targetLocaleId) {
  const handleText = () => {
    // try condensing the spans, but fallback to the text string
    const source = node.spans?.map(span => span.text).join('') ?? node.text;
    const target =
      variantNode?.spans?.map(span => span.text).join('') ?? variantNode?.text;
    if (source) {
      dictionary[node.id] = { source, target: target ?? source };
    }
  };

  const handleImage = () => {
    const source = node.accessibilityLabel;
    const target = variantNode?.accessibilityLabel;
    if (source) {
      dictionary[node.id] = { source, target: target ?? source };
    }
  };

  const handleTextInput = () => {
    [LABEL, ERROR_LABEL, PLACEHOLDER].forEach(key => {
      extractLocalizedContent(
        node[key],
        variantNode?.[key],
        dictionary,
        targetLocaleId
      );
    });
  };

  const handleOptionSelect = () => {
    [LABEL, ERROR_LABEL].forEach(key => {
      extractLocalizedContent(
        node[key],
        variantNode?.[key],
        dictionary,
        targetLocaleId
      );
    });
    node.options.forEach((option, index) => {
      extractLocalizedContent(
        option.content,
        variantNode?.options?.[index].content,
        dictionary,
        targetLocaleId
      );
      if (option.selectedContent !== undefined) {
        extractLocalizedContent(
          option.selectedContent,
          variantNode?.options?.[index].selectedContent,
          dictionary,
          targetLocaleId
        );
      }
    });
  };

  return {
    text: handleText,
    image: handleImage,
    textInput: handleTextInput,
    optionSelect: handleOptionSelect,
  };
}

/**
 * Extract localized content from a node
 *
 * @param {Node} node - Experience node or content block
 * @param {object} variantNode - node or content block inside variations
 * @param {object} dictionary - object that will contain source/target localized texts
 * @param {string} targetLocaleId - Locale ID
 * @return {object} - object to be parsed to xliff
 */
function extractLocalizedContent(
  node,
  variantNode,
  dictionary,
  targetLocaleId
) {
  const { type, blockType, variations, items, content } = node ?? {};

  // if the node is a localized block, extract the content from the variant
  if (
    type === BLOCK &&
    blockType === LOCALIZED &&
    variations?.[targetLocaleId] !== undefined
  ) {
    return extractLocalizedContent(
      content,
      variations[targetLocaleId],
      dictionary,
      targetLocaleId
    );
  }

  if (items !== undefined) {
    items.forEach((item, index) => {
      extractLocalizedContent(
        item,
        variantNode?.items?.[index],
        dictionary,
        targetLocaleId
      );
    });
    return dictionary;
  }

  if (content !== undefined) {
    return extractLocalizedContent(
      content,
      variantNode?.content,
      dictionary,
      targetLocaleId
    );
  }

  if (type) {
    handleTypes(node, variantNode, dictionary, targetLocaleId)[type]?.();
  }

  return dictionary;
}

/**
 * Generate xliff file based in the experience JSON and the target locale
 *
 * @param {object} experienceJSON - Experience JSON object
 * @param {string} targetLocaleId - Locale ID
 * @param {string} targetLocaleCode - Locale code
 * @return xliff document
 */
export const generateXLIFF = async (
  experienceJSON,
  targetLocaleId,
  targetLocaleCode
) => {
  // this object is going to be used for the xliff generation
  const objectToLocalize = {
    resources: {
      [experienceJSON.id]: {},
    },
    targetLanguage: targetLocaleCode,
  };

  experienceJSON.steps.forEach(stepGroup => {
    stepGroup.children.forEach(stepChild => {
      objectToLocalize.resources[experienceJSON.id][stepChild.id] = {
        // this is used for the xliff to create groups for each step
        groupUnits: extractLocalizedContent(
          stepChild.content,
          undefined,
          {},
          targetLocaleId
        ),
      };
    });
  });

  const document = await xliff.jsToXliff12(objectToLocalize);
  return document;
};
