import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { selectAccountId } from 'ext/entities/account';
import { FieldSet } from 'ext/components/ui';
import { Shape as BlockContentShape } from 'entities/block';
import ImageUploader from 'components/ImageUploader';

function ImageUpload({ content, onChange, accountId }) {
  const { id, style } = content ?? {};

  const handleUpload = useCallback(
    ({ url, intrinsicSize }) => {
      onChange({
        blockId: id,
        contentChunk: {
          style: {
            ...style,
            backgroundImage: {
              ...style.backgroundImage,
              imageUrl: url,
              blurHash: '',
              intrinsicSize,
            },
          },
        },
        useOriginalId: true,
      });
    },
    [id, style, onChange]
  );

  return (
    <FieldSet>
      <ImageUploader onUpload={handleUpload} accountId={accountId} />
    </FieldSet>
  );
}

ImageUpload.propTypes = {
  accountId: PropTypes.string,
  content: BlockContentShape,
  onChange: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    accountId: selectAccountId(state),
  };
};

export default connect(mapStateToProps, null)(ImageUpload);
