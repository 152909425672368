import React, { useState, useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Icon } from '@appcues/sonar';
import { faPalette } from '@fortawesome/pro-solid-svg-icons/faPalette';
import { faImage } from '@fortawesome/pro-solid-svg-icons/faImage';
import {
  Accordion,
  Label,
  FieldSet,
  RadioButton,
  RadioButtonGroup,
} from 'ext/components/ui';
import { Shape as BlockContentShape } from 'entities/block';
import { selectUserPreferences } from 'entities/user-preferences';
import { THEMES } from 'lib/user-preferences';
import { HERO, BLOCK_LABELS } from 'lib/block';
import ColorInput from 'components/ColorInput';
import { heroBackgroundTemplate } from 'components/Editor/Templates/Content/hero';
import {
  Content,
  Controls,
  RadioButtonLabel,
  useStyleSettings,
} from 'components/SideBarSettings/Shared';
import ContentActions from 'components/SideBarSettings/Content/ContentActions';
import ImageUpload from './ImageUpload';
import Style from './Style';
import AlignmentSpacing from './AlignmentSpacing';

const MODES = {
  image: 'image',
  color: 'color',
};

export function HeroSettings({ content, onChange, theme }) {
  const { id, style } = content ?? {};
  const { imageUrl } = style?.backgroundImage ?? {};

  const [editMode, setEditMode] = useState(
    imageUrl ? MODES.image : MODES.color
  );
  const [previousBackgroundImage, setPreviousBackgroundImage] = useState({
    ...heroBackgroundTemplate.backgroundImage,
  });

  const handleContentChange = updatedContent => {
    onChange({
      blockId: id,
      contentChunk: updatedContent,
      useOriginalId: true,
    });
  };

  const handleChange = useCallback(
    patch => {
      onChange({
        blockId: id,
        contentChunk: { style: patch },
        useOriginalId: true,
      });
    },
    [id, onChange]
  );

  const handleBackgroundImageChange = () => {
    if (editMode !== MODES.image) {
      setEditMode(MODES.image);
      onChange({
        blockId: id,
        contentChunk: {
          style: {
            ...style,
            backgroundImage: previousBackgroundImage,
          },
        },
        useOriginalId: true,
      });
    }
  };

  const handleColorMode = () => {
    if (editMode !== MODES.color) {
      setEditMode(MODES.color);

      const { backgroundImage, ...rest } = style;
      setPreviousBackgroundImage(backgroundImage);

      onChange({
        blockId: id,
        contentChunk: {
          style: {
            ...rest,
          },
        },
        useOriginalId: true,
      });
    }
  };

  const [valueFor, handleChangeFor] = useStyleSettings({
    onChange: handleChange,
    style,
    theme,
  });

  return (
    <Content>
      <Controls>
        <FieldSet>
          <RadioButtonGroup>
            <RadioButton
              onClick={handleBackgroundImageChange}
              selected={editMode === MODES.image}
            >
              <RadioButtonLabel>
                <Icon icon={faImage} />
                Image
              </RadioButtonLabel>
            </RadioButton>
            <RadioButton
              onClick={handleColorMode}
              selected={editMode === MODES.color}
            >
              <RadioButtonLabel>
                <Icon icon={faPalette} />
                Color
              </RadioButtonLabel>
            </RadioButton>
          </RadioButtonGroup>
        </FieldSet>

        {editMode === MODES.image && (
          <ImageUpload content={content} onChange={onChange} />
        )}

        {editMode === MODES.color && (
          <FieldSet>
            <Label htmlFor="button-color-input">Background Color</Label>
            <ColorInput
              id="button-color-input"
              color={valueFor.backgroundColor}
              onChange={handleChangeFor.backgroundColor}
            />
          </FieldSet>
        )}
      </Controls>

      <Accordion.Root
        collapsible="true"
        defaultValue={['style', 'alignment-spacing']}
        type="multiple"
      >
        {editMode === MODES.image && (
          <Style content={content} onChange={onChange} theme={theme} />
        )}

        <AlignmentSpacing
          style={style}
          spacingLabel={BLOCK_LABELS[HERO]}
          onChange={handleContentChange}
        />
        <ContentActions blockId={id} context={HERO} />
      </Accordion.Root>
    </Content>
  );
}

HeroSettings.propTypes = {
  content: BlockContentShape,
  onChange: PropTypes.func,
  theme: PropTypes.oneOf(THEMES),
};

const mapStateToProps = state => ({
  theme: selectUserPreferences(state).theme,
});

export default connect(mapStateToProps)(HeroSettings);
