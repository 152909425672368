import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { easing } from 'ext/lib/style';
import { ButtonGroup } from './Button';
import Icon from './FontIcon';
import BrandMark from './BrandMark';
import ToolBarToggle from './ToolBarToggle';

const Bar = styled.div`
  align-items: center;
  display: flex;
  height: var(--bottom-bar-height);
  justify-content: space-between;
  padding-left: ${({ hasBrandMarkMenu }) =>
    hasBrandMarkMenu ? '84px' : 'var(--bottom-bar-height)'};
  padding-right: 20px;

  ${({ hasBrandMarkMenu }) =>
    !hasBrandMarkMenu &&
    `
    ${BrandMark} {
      bottom: calc(var(--bottom-bar-height) / 2);
      left: calc(var(--bottom-bar-height) / 2);
      position: absolute;
      transform: translate3d(-50%, 50%, 0);
    }
  `}

  ${ButtonGroup} {
    min-height: 35px;
  }
`;

const Container = styled.div`
  background-color: var(--background-dark);
  bottom: 0;
  color: var(--text-color);
  left: 0;
  position: fixed;
  transform: ${({ collapsed }) =>
    collapsed ? 'translateY(100%)' : 'translateY(0)'};
  transition: ${easing('transform')};
  width: 100%;
  box-shadow: 0px -4px 16px rgba(0, 0, 0, 0.25);

  ${ToolBarToggle} {
    bottom: 100%;
    border-top-right-radius: 4px;
    left: 0;
  }
`;

export default function BottomBar({
  children,
  collapsed,
  onClick,
  messages = [],
  menu = null,
  className,
}) {
  const hasBrandMarkMenu = Boolean(menu);

  return (
    <Container collapsed={collapsed} className={className}>
      {onClick && (
        <ToolBarToggle onClick={onClick}>
          {collapsed ? 'show' : 'hide'}
          <Icon icon={collapsed ? 'caret-up' : 'caret-down'} />
        </ToolBarToggle>
      )}
      {messages.map(message => (
        <Fragment key={message.key}>{message}</Fragment>
      ))}
      <Bar hasBrandMarkMenu={hasBrandMarkMenu}>
        {hasBrandMarkMenu ? menu : <BrandMark />}
        {children}
      </Bar>
    </Container>
  );
}

BottomBar.propTypes = {
  children: PropTypes.node,
  collapsed: PropTypes.bool,
  messages: PropTypes.arrayOf(PropTypes.element),
  menu: PropTypes.node,
  onClick: PropTypes.func,
  className: PropTypes.string,
};
