import { v4 as uuid } from 'uuid';
import { getContinue } from 'lib/actions';
import { TEXT, BUTTON, STACK } from 'lib/block';
import generateElementTemplate from 'components/Editor/Templates/generate-element-template';

/**
 * Create canned embed promotion step
 *
 * @param {string} previewStepId - id to preview the step template before creation
 * @return {Step} Canned step group
 */
export const generateEmbedPromotionStep = ({ previewStepId }) => {
  const buttonElementId = uuid();

  return {
    ...(previewStepId && { id: previewStepId }),
    type: 'embed',
    contentType: 'application/json',
    content: {
      id: uuid(),
      type: STACK,
      orientation: 'vertical',
      style: {},
      items: [
        generateElementTemplate({
          template: TEXT,
          blockId: uuid(),
          elementId: uuid(),
          newRow: true,
          content: {
            text: 'Go further with Premium',
            style: {
              fontSize: 24,
              fontName: 'System Default Bold',
              foregroundColor: { light: '#425678' },
              textAlignment: 'leading',
              horizontalAlignment: 'leading',
              marginTop: 16,
              marginBottom: 12,
            },
          },
        }),
        generateElementTemplate({
          template: TEXT,
          blockId: uuid(),
          elementId: uuid(),
          newRow: true,
          content: {
            text: 'Just $3.99/mo for your first 3 months',
            style: {
              fontSize: 13,
              fontName: 'System Default Bold',
              foregroundColor: { light: '#425678' },
              textAlignment: 'leading',
              horizontalAlignment: 'leading',
              marginTop: 16,
              marginBottom: 12,
            },
          },
        }),
        generateElementTemplate({
          template: BUTTON,
          blockId: uuid(),
          elementId: buttonElementId,
          newRow: true,
          content: generateElementTemplate({
            template: TEXT,
            blockId: uuid(),
            elementId: uuid(),
            content: {
              text: 'Learn More',
              style: {
                fontSize: 18,
                foregroundColor: { light: '#FFFFFF' },
              },
            },
          }),
          style: {
            width: -1,
          },
        }),
      ],
    },
    actions: {
      [buttonElementId]: [getContinue()],
    },
  };
};
