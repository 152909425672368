import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import tinycolor from 'tinycolor2';
import { generateColor } from 'ext/lib/color';
import useClickOutside from 'ext/lib/hooks/use-click-outside';
import useToggle from 'ext/lib/hooks/use-toggle';
import {
  CurrentColor,
  FontIcon,
  Input,
  RadioButton,
  RadioButtonGroup,
} from 'ext/components/ui';
import { ColorPicker } from 'ext/components/ColorPicker';
import Tether from 'ext/components/Tether';
import { THEMES, setUserPreferences } from 'lib/user-preferences';
import {
  selectUserPreferences,
  updateUserPreferences,
} from 'entities/user-preferences';
import { DEFAULT_COLORS } from 'components/SideBarSettings/Shared';
import {
  ColorPickerContainer,
  ColorPickerTitle,
  CurrentColorWrapper,
  Header,
  InputContainer,
  MainContainer,
  Group,
} from './styled';

const [LIGHT, DARK] = THEMES;

/**
 * Get conditionally alpha aware hex string from color
 *
 * @param {string} value - Color input
 * @return {string} Color hex string
 */
const getHexString = value => {
  const color = tinycolor(value);
  return color.getAlpha() === 1 ? color.toHexString() : color.toHex8String();
};

export const ColorInput = ({
  id,
  label,
  color,
  placeholder = DEFAULT_COLORS.selectedColor,
  onChange,
  theme,
  onUserPreferencesUpdate,
}) => {
  const $container = useRef();
  const $input = useRef();
  const $colorPicker = useRef();

  const [currentColor, setCurrentColor] = useState(color);
  const [isColorPickerVisible, toggleColorPickerVisible] = useToggle(false);

  useEffect(() => {
    setCurrentColor(color);
  }, [color]);

  useClickOutside(
    [$container, $input, $colorPicker],
    isColorPickerVisible && toggleColorPickerVisible
  );

  const onThemeChange = value => {
    onUserPreferencesUpdate('theme', value);
    setUserPreferences('theme', value);
  };

  const handleChangeComplete = value => {
    const hex = getHexString(value);
    $input.current.value = hex;
    setCurrentColor(generateColor(value));
    onChange(hex);
  };

  const handleInputChange = value => {
    // If valid color, then trigger change with hex8 value
    if (tinycolor(value).isValid()) {
      const hex = getHexString(value);
      $input.current.value = value;
      setCurrentColor(hex);
      onChange(hex);
    }
    // Or if the value is empty/cleared, return null
    else {
      $input.current.value = value;

      if (value === '') {
        onChange(null);
        setCurrentColor();
      }
    }
  };

  const handleInputBlur = value => {
    // If the value is the same as the current color, we do nothing
    if (currentColor === value) return;

    // If the value is invalid, we reset to the previous valid color
    if (!tinycolor(value).isValid() && !!currentColor) {
      $input.current.value = currentColor;
    } else {
      handleInputChange(value);
    }
  };

  const colorPicker = (
    <ColorPickerContainer role="dialog" ref={$colorPicker}>
      <Header>
        <ColorPickerTitle tabIndex="0" id="title">
          Color
        </ColorPickerTitle>

        <Group>
          <RadioButtonGroup>
            <RadioButton
              selected={theme === LIGHT}
              onClick={() => onThemeChange(LIGHT)}
            >
              <FontIcon icon="sun" aria-label="Light mode" />
            </RadioButton>
            <RadioButton
              selected={theme === DARK}
              onClick={() => onThemeChange(DARK)}
            >
              <FontIcon icon="moon" aria-label="Dark mode" />
            </RadioButton>
          </RadioButtonGroup>

          <FontIcon
            icon="times"
            aria-label="Close color picker"
            onClick={() => toggleColorPickerVisible(false)}
          />
        </Group>
      </Header>
      <ColorPicker
        onChangeComplete={handleChangeComplete}
        color={tinycolor(currentColor).toRgb()}
      />
    </ColorPickerContainer>
  );

  return (
    <Tether
      attachment={colorPicker}
      placement="bottom"
      visible={isColorPickerVisible}
    >
      <MainContainer ref={$container}>
        <InputContainer key={color}>
          <Input
            ref={$input}
            id={id}
            aria-label={label}
            defaultValue={color}
            placeholder={placeholder}
            onChange={({ target }) => handleInputChange(target.value)}
            onBlur={({ target }) => handleInputBlur(target.value)}
          />

          <CurrentColorWrapper>
            <CurrentColor
              aria-label="Current color"
              color={currentColor}
              onClick={() => toggleColorPickerVisible(true)}
            />
          </CurrentColorWrapper>
        </InputContainer>
      </MainContainer>
    </Tether>
  );
};

ColorInput.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  color: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  theme: PropTypes.oneOf(THEMES),
  onUserPreferencesUpdate: PropTypes.func,
};

const mapStateToProps = state => ({
  theme: selectUserPreferences(state).theme,
});

const mapDispatchToProps = {
  onUserPreferencesUpdate: updateUserPreferences,
};

export default connect(mapStateToProps, mapDispatchToProps)(ColorInput);
