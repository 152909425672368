import React, { Children, cloneElement, useState } from 'react';
import PropTypes from 'prop-types';
import Fade from 'ext/components/Fade';
import Portal from 'ext/components/Portal';
import mergeRefs from 'ext/lib/merge-refs';
import { TOP_MENU_HEIGHT } from 'ext/constants';
import useTether from './use-tether';
import { Target, Reset, Attachment } from './styled';

/**
 * FIXME: There is an edge-case where the CSSTransition animation will reset
 *        causing the selector to disappear (opacity: 0) when the window is
 *        resized to the point where an optimal position is not found. While not
 *        ideal, the issue only seems to occur when the window is resized to
 *        something very small and fixed by clicking anywhere on the page and
 *        reopening the selector.
 */

const Tether = ({
  attachment,
  children,
  delay = 0,
  distance,
  minTop = TOP_MENU_HEIGHT,
  offset,
  placement = 'top',
  timeout = 200,
  visible,
  wrapped,
  allowedPlacements,
  className,
}) => {
  // Small hack to force another reposition after the fade has started. Due to
  // the timing of CSSTransitions and how these components were designed e.g.
  // Fade, the first reposition is used render the attachment into the DOM while
  // the reposition forced by this prop will correct it with the actual position
  const [attached, setAttached] = useState(false);

  const [refs, tether] = useTether({
    attached,
    minTop,
    offset,
    placement,
    visible,
    allowedPlacements,
  });

  const renderTarget = () => {
    // If the wrapped component already has ref defined, rather than cloning the
    // element we can wrap the target and have the wrapper bound with the ref
    if (wrapped) {
      return (
        <Target data-tether="wrapped" ref={refs.target}>
          {children}
        </Target>
      );
    }

    // Assert that a single element is wrapped by Tether e.g. no fragments
    const child = Children.only(children);

    // To ensure that structural styles are preserved e.g. flex childs, clone the
    // wrapped component and pass forward the merged refs
    return cloneElement(child, {
      'data-tether': 'target',
      ref: mergeRefs([child.ref, refs.target]),
    });
  };

  const renderAttachment = () => {
    // If the provided attachment is a render function, invoke the function and
    // pass the ref as an argument. This is useful for situations such as class
    // components where the ref is not forwarded via the ref prop
    if (typeof attachment === 'function') {
      return attachment({ tether, ref: refs.attachment });
    }

    // Assert that a single element is provided as an attachment e.g. no fragments
    const child = Children.only(attachment);

    // Clone the element and pass the ref to be used for positioning
    return cloneElement(child, {
      ref: mergeRefs([child.ref, refs.attachment]),
      tether,
    });
  };

  return (
    <>
      {renderTarget()}

      <Portal timeout={delay + timeout}>
        <Reset className={className}>
          <Fade
            delay={delay}
            distance={distance}
            // FIXME: When the attachment's fade direction changes, the
            //        Fade component re-renders and can get into a state where
            //        it resets the CSSTransition but not have enough time to
            //        re-animate before the next repositioning causing the
            //        attachment to be hidden. Since the fade direction is
            //        mostly for UI flavoring, temporarily giving it a static
            //        value of 'center' so that it does not affect functionality
            //        of tethered attachments.
            from={'center' || tether.fade}
            onEnter={() => setAttached(true)}
            onExit={() => setAttached(false)}
            timeout={timeout}
            visible={visible && tether.position != null}
          >
            <Attachment
              data-placement={tether.placement}
              style={tether.position}
            >
              {renderAttachment()}
            </Attachment>
          </Fade>
        </Reset>
      </Portal>
    </>
  );
};

const Placements = [
  'top-left',
  'top',
  'top-right',
  'right-top',
  'right',
  'right-bottom',
  'bottom-right',
  'bottom',
  'bottom-left',
  'left-bottom',
  'left',
  'left-top',
];

Tether.propTypes = {
  // Component that will be attached/tethered to target
  attachment: PropTypes.oneOfType([PropTypes.func, PropTypes.node]),

  // Target component to tether attachment
  children: PropTypes.node,
  minTop: PropTypes.number,

  // Attachment offset based on tethered point. Despite being called x/y, the
  // actual offset is parallel/perpendicular respectively. So when tethered on
  // the left/right side of the target, x will actually offset up while y will
  // offset left/right respectively.
  offset: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
  }),

  // Preferred attachment placement. Tether will initially attempt to render at
  // this placement before attempting other options and finally falling back to
  // this placement is no other placements are deemed better.
  placement: PropTypes.oneOf(Placements),
  // This prop will make Tether only find the best optimal placement
  // within the list passed as prop, ignoring if the list elements
  // are really the best placement at that time.
  // useful for use cases like dropdown menu list, where we only want it to open
  // top or bottom.
  allowedPlacements: PropTypes.arrayOf(PropTypes.oneOf(Placements)),

  // Wrap target child element with a dummy div to accept the ref if the target
  // component is unable to accept it for some reason e.g. class component.
  wrapped: PropTypes.bool,

  // Inherited fade props
  delay: PropTypes.number,
  distance: PropTypes.number,
  timeout: PropTypes.number,
  visible: PropTypes.bool,

  className: PropTypes.string,
};

Tether.defaultProps = {
  allowedPlacements: Placements,
  className: '',
};

export default Tether;
