import React from 'react';
import PropTypes from 'prop-types';
import { set } from 'ext/lib/local-storage';
import { Logo, P } from 'ext/components/ui';
import StyleProvider from 'styles/StyleProvider';
import LightTheme from 'components/LightTheme';
import { DEMOCUES_KEY, TYPES, generateDeeplink } from './generate-deeplink';
import { Wrapper, Dialog, Title, ExternalLink } from './styled';

const ANDROID_APP_LINK =
  'https://play.google.com/store/apps/details?id=com.appcues.mobileshowcase';
const IOS_APP_LINK =
  'https://apps.apple.com/us/app/appcues-mobile-showcase/id6464720573';

/**
 * Component to render a preview proxy page that will redirect to the app
 * Example of pages that will use this component:
 *
 * - Democues: ?democues=enabled|disabled
 * - Debugger: ?type=debugger&appId=<appId>
 * - Experience Preview: ?type=preview&appId=<appId>&experienceId=<experienceId>
 * - Capture Screen: ?type=capture_screen&appId=<appId>&sdkToken=<sdkToken>
 * - Push Preview: ?type=push_preview&appId=<appId>&pushId=<pushId>
 *
 */
export default function PreviewProxy({ params }) {
  let deepLink = '';
  const { type, appId, accountId, experienceId, localeId, democues } = params;

  const showcaseLocale = localeId ? `?locale_id=${localeId}` : '';
  const showcaseLink = `appcues-showcase://preview/${accountId}/${appId}/${experienceId}${showcaseLocale}`;

  if (democues) {
    set(DEMOCUES_KEY, democues === 'enabled');
  } else {
    deepLink = generateDeeplink({ ...params });
    window.location.replace(deepLink);
  }

  return (
    <StyleProvider>
      <LightTheme>
        <Wrapper>
          <Dialog>
            <Logo />
            <Title bold>
              {democues
                ? `Democues mode ${democues}!`
                : 'Redirecting to your app'}
            </Title>

            {!democues && (
              <P>
                If you are not redirected to your app,{' '}
                <ExternalLink href={deepLink}>click this link.</ExternalLink>
              </P>
            )}

            <P>
              For troubleshooting help, please see our{' '}
              <ExternalLink href="https://docs.appcues.com/">
                documentation.
              </ExternalLink>
            </P>

            {type === 'preview' && (
              <P>
                SDK not installed? Preview in the Showcase app instead,{' '}
                <ExternalLink href={showcaseLink}>
                  click this link.
                </ExternalLink>{' '}
                <br />
                Get the app here: <br />
                <ExternalLink href={ANDROID_APP_LINK}>
                  Android
                </ExternalLink> or{' '}
                <ExternalLink href={IOS_APP_LINK}>iOS</ExternalLink>
              </P>
            )}
          </Dialog>
        </Wrapper>
      </LightTheme>
    </StyleProvider>
  );
}

PreviewProxy.propTypes = {
  params: PropTypes.shape({
    type: PropTypes.oneOf(Object.values(TYPES)).isRequired,
    appId: PropTypes.string.isRequired,
    accountId: PropTypes.string,
    experienceId: PropTypes.string,
    pushId: PropTypes.string,
    localeId: PropTypes.string,
    userId: PropTypes.string,
    sdkToken: PropTypes.string,
    democues: PropTypes.oneOf(['enabled', 'disabled']),
  }),
};
