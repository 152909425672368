import React from 'react';
import PropTypes from 'prop-types';
import { Accordion } from 'ext/components/ui';
import { Shape as BlockContentShape } from 'entities/block';
import { Content, Style, SizeSpacing } from 'components/SideBarSettings/Shared';
import ContentActions from 'components/SideBarSettings/Content/ContentActions';
import { IMAGE } from 'lib/block';
import Accessibility from './Accessibility';
import ImageUpload from './ImageUpload';

export function ImageSettings({ content, onChange }) {
  const { id: blockId } = content ?? {};

  return (
    <Content>
      <ImageUpload content={content} onChange={onChange} />
      <Accordion.Root
        collapsible="true"
        defaultValue={['style', 'size-spacing', 'accessibility']}
        type="multiple"
      >
        <Style content={content} onChange={onChange} />
        <SizeSpacing
          content={content}
          onChange={onChange}
          hasMargin
          horizontalAlignmentOnly
        />
        <Accessibility content={content} onChange={onChange} />
        <ContentActions blockId={blockId} context={IMAGE} />
      </Accordion.Root>
    </Content>
  );
}

ImageSettings.propTypes = {
  content: BlockContentShape,
  onChange: PropTypes.func,
};

export default ImageSettings;
