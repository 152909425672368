import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useDrag, useDrop } from 'react-dnd';
import { del } from 'object-path-immutable';
import useAnalytics from 'ext/lib/hooks/use-analytics';
import { focus } from 'entities/block';
import { selectStepGroup } from 'entities/step-groups';
import { selectStepChild, update, ContentShape } from 'entities/step-children';
import { selectActiveLocale } from 'entities/locales';
import { selectSelected } from 'entities/selected';
import { LAYOUT_TRAITS } from 'lib/trait';
import {
  BLOCK_LABELS,
  SURVEY,
  BLOCK_CATEGORIES,
  getRowAndBlockIndex,
  updateStickyRow,
  getSurveyBlocks,
} from 'lib/block';
import { removeSubmitFormAction, Shape as ActionsShape } from 'lib/actions';
import ActionsToolbar from 'components/ActionsToolbar';
import { cloneElement, deleteElement, getStickyRow } from './handlers';
import { BlockSelection, BlockOverlay, StyledTether } from './styled';

export const BLOCK_TYPE = 'BLOCK_TYPE';

const BlockContainer = ({
  id,
  blockType,
  stepId,
  layoutTrait,
  content,
  actions,
  selectedBlock,
  hasActiveLocale,
  children,
  onDrop,
  onClick,
  onUpdate,
}) => {
  const { track } = useAnalytics();
  const [direction, setDirection] = useState('left');
  const $actionsToolbar = useRef();

  const {
    rowId: selectedRow,
    allowedToStickyPosition,
    isSticky,
  } = (selectedBlock &&
    getStickyRow({ layoutTrait, content, blockId: selectedBlock })) ||
  {};

  const handleTrack = action =>
    track('Mobile Builder interaction', {
      name: `${action} ${BLOCK_LABELS[blockType]} Element`,
      component: 'BlockContainer',
    });

  const handleClick = () => {
    if (selectedBlock !== id) {
      onClick(id);
    }
  };

  const handleStickyElementClick = () => {
    track('Mobile Builder interaction', {
      name: `${!isSticky ? 'Sticked' : 'Unsticked'} Row Element`,
      component: 'BlockContainer',
    });

    const updatedContent = updateStickyRow({
      content,
      rowId: selectedRow,
      stickyPosition: allowedToStickyPosition,
    });

    onUpdate(stepId, updatedContent);
  };

  const sticky = {
    row: {
      id: selectedRow,
      allowedToStickyPosition,
      isSticky,
    },
    handleSticky: allowedToStickyPosition && handleStickyElementClick,
  };

  const handleActions = contentItems => {
    const isSurveyDeletion = BLOCK_CATEGORIES[SURVEY].includes(blockType);
    if (isSurveyDeletion && getSurveyBlocks(contentItems).length === 0) {
      const updatedActions = removeSubmitFormAction(actions);
      return { actions: updatedActions };
    }

    const blockId = children.props.id;
    const updatedActions = del(actions, blockId);
    return { actions: updatedActions };
  };

  const handleDeleteElementClick = () => {
    handleTrack('Deleted');
    const updatedContent = deleteElement(content, id);

    onClick();
    onUpdate(stepId, {
      ...handleActions(updatedContent.content),
      ...updatedContent,
    });
  };

  const handleCloneElementClick = async () => {
    handleTrack('Cloned');
    const { content: updatedContent, elementId } = cloneElement(content, id);

    const elementActions = actions[children.props.id];
    if (elementActions !== undefined) {
      const updatedActions = { ...actions, [elementId]: elementActions };
      await onUpdate(stepId, { actions: updatedActions });
    }

    await onUpdate(stepId, { content: updatedContent });
    onClick();
  };

  const ref = useRef(null);
  const {
    offsetHeight: height,
    offsetWidth: width,
    offsetLeft: left,
  } = ref.current ?? {};

  const [{ isDragging }, drag] = useDrag(() => ({
    type: BLOCK_TYPE,
    item: { id },
    canDrag: !hasActiveLocale,
    collect: monitor => ({
      isDragging: !!monitor.isDragging(),
    }),
  }));

  const [{ isOver }, drop] = useDrop({
    accept: BLOCK_TYPE,
    hover: item => {
      const [dropRow, dropBlock] = getRowAndBlockIndex(content, id);
      const [dragRow, dragBlock] = getRowAndBlockIndex(content, item.id);

      if (dragRow === dropRow) {
        setDirection(dragBlock > dropBlock ? 'left' : 'right');
      } else {
        setDirection('left');
      }
    },
    drop: item => {
      const draggingId = item.id;
      const droppingId = id;

      // Don't replace items with themselves
      if (draggingId === droppingId) {
        return;
      }

      onDrop({ draggingId, droppingId });
    },
    collect: monitor => ({
      isAbove: monitor.getDifferenceFromInitialOffset()?.y < 0,
      isOver: !!monitor.isOver(),
    }),
  });

  drag(drop(ref));

  return (
    <>
      <BlockSelection
        ref={ref}
        onClick={handleClick}
        tabIndex={0}
        isDragging={isDragging}
        isOver={isOver}
        data-apc-block={blockType}
        data-selected={selectedBlock === id}
        data-testid={id}
        direction={direction}
        data-dragging={isDragging}
      >
        <StyledTether
          attachment={
            <ActionsToolbar
              forwardRef={$actionsToolbar}
              sticky={sticky}
              handleDelete={handleDeleteElementClick}
              handleClone={handleCloneElementClick}
            />
          }
          visible={selectedBlock === id && !hasActiveLocale}
          placement="top-right"
          offset={{ x: -5 }}
          wrapped
        >
          {children}
        </StyledTether>
      </BlockSelection>
      {isDragging && <BlockOverlay height={height} width={width} left={left} />}
    </>
  );
};

BlockContainer.propTypes = {
  id: PropTypes.string,
  blockType: PropTypes.string,
  children: PropTypes.node,
  stepId: PropTypes.string,
  layoutTrait: PropTypes.oneOf(LAYOUT_TRAITS),
  content: ContentShape,
  actions: ActionsShape,
  selectedBlock: PropTypes.string,
  hasActiveLocale: PropTypes.bool,
  onClick: PropTypes.func,
  onUpdate: PropTypes.func,
  onDrop: PropTypes.func,
};

const mapStateToProps = state => {
  const {
    stepGroup: stepGroupId,
    stepChild: stepId,
    block: selectedBlock,
  } = selectSelected(state) ?? {};
  const stepGroup = selectStepGroup(state, stepGroupId) ?? {};
  const { type: layoutTrait } = stepGroup.editor ?? {};
  const { content, actions } = selectStepChild(state, stepId) ?? {};
  const { hasActiveLocale } = selectActiveLocale(state) ?? {};

  return {
    stepId,
    layoutTrait,
    content,
    actions,
    selectedBlock,
    hasActiveLocale,
  };
};

const mapDispatchToProps = {
  onClick: focus,
  onUpdate: update,
};

export default connect(mapStateToProps, mapDispatchToProps)(BlockContainer);
