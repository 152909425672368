import { createCollectionReducer, DELETED } from 'ext/lib/collections';
import { selectExperience } from 'entities/experiences';
import { selectSelected } from 'entities/selected';
import { selectStepChild } from 'entities/step-children';
import { selectUserPreferences } from 'entities/user-preferences';
import { getLocaleVariations, DEFAULT_LOCALE } from 'lib/localization';
import { TYPE } from './actions';

export default createCollectionReducer(TYPE);

export const selectLocales = state =>
  Object.fromEntries(
    Object.entries(state[TYPE]).filter(([, locale]) => !locale[DELETED])
  );

export const selectActiveLocale = state => {
  const { id: experienceId } = selectExperience(state) || {};
  const { locale } = selectUserPreferences(state) || {};
  const { stepChild } = selectSelected(state) || {};
  const step = selectStepChild(state, stepChild) || {};

  const activeLocales = getLocaleVariations(step.content);
  const isValidLocale = activeLocales.includes(locale?.id);
  const selectedLocale = isValidLocale &&
    locale?.experienceId === experienceId && {
      ...locale,
      hasActiveLocale: true,
    };

  return {
    ...DEFAULT_LOCALE,
    ...selectedLocale,
  };
};
