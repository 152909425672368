import React from 'react';
import PropTypes from 'prop-types';
import { FieldSet, Switch } from 'ext/components/ui';
import {
  closeType,
  submitFormType,
  getSubmitForm,
  Shape as ActionsShape,
} from 'lib/actions';

export default function SubmitFormOption({ actions, onChange }) {
  const hasSubmitForm = actions.some(action => action.type === submitFormType);
  const isCloseType = actions.length === 1 && actions[0].type === closeType;

  const handleSubmitFormChange = submitForm => {
    onChange({
      submitForm: submitForm
        ? [getSubmitForm({ skipValidation: isCloseType })]
        : [],
    });
  };

  return (
    <FieldSet>
      <Switch
        label="Submit survey responses"
        checked={hasSubmitForm}
        onChange={handleSubmitFormChange}
      />
    </FieldSet>
  );
}

SubmitFormOption.propTypes = {
  actions: PropTypes.arrayOf(ActionsShape),
  onChange: PropTypes.func,
};
