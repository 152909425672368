import styled, { css } from 'styled-components';
import FontIcon from './FontIcon';
import StepIcon from './StepIcon';
import ButtonIcon from './ButtonIcon';

const iconStyles = css`
  color: var(--white);
  font-size: var(--regular);
  height: 20px;
  text-align: center;
  vertical-align: text-bottom;
`;

export const Menu = styled.div`
  background: var(--background-light);
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  width: 250px;
`;

export default Menu;

export const BottomBarMenu = styled.div`
  bottom: 65px;
  position: absolute;
`;

export const List = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  &:not(:last-of-type) {
    border-bottom: 2px solid var(--tint-white);
  }
`;

export const MenuHeading = styled.h2`
  color: var(--white);
  font-size: var(--x-small);
  font-weight: var(--bold);
  text-transform: uppercase;
  letter-spacing: 0.6px;
  margin: 0;
  padding: 12px 16px 8px;
  & + ${List} {
    padding-bottom: 8px;
  }
`;

export const ListItem = styled.li`
  align-items: center;
  border-radius: 8px;
  color: var(--white);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 8px 0px;
  position: relative;
  padding: 13px;

  &:hover {
    background: var(--background-x-light);
  }

  & ${FontIcon}, ${StepIcon} {
    ${iconStyles}
    margin-right: 15px;
    min-width: 30px;
    padding: 0px 7px;
  }

  ${ButtonIcon} {
    ${iconStyles}
    margin-right: 17px;
    margin-left: 4px;
    min-width: 28px;
    padding: 1px 4px;
  }
`;

export const ListBody = styled.div`
  flex: 1;
`;

export const MenuOverlay = styled.div`
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
`;
