import { v4 as uuid } from 'uuid';
import { BLOCK, IMAGE, IMAGE_WITH_TEXT, STACK, TEXT } from 'lib/block';

const imageWithTextTemplate = ({ blockId, elementId }) => ({
  type: BLOCK,
  blockType: IMAGE_WITH_TEXT,
  id: blockId,
  content: {
    type: STACK,
    id: elementId,
    orientation: 'horizontal',
    distribution: 'center',
    style: {
      horizontalAlignment: 'leading',
      verticalAlignment: 'top',
      paddingTop: 8,
      paddingBottom: 8,
      paddingLeading: 16,
      paddingTrailing: 16,
    },
    items: [
      {
        type: IMAGE,
        id: uuid(),
        imageUrl:
          'https://res.cloudinary.com/dnjrorsut/image/upload/v1660836995/103523/roh2p8ba1mtwtzganmad.png',
        contentMode: 'fit',
        intrinsicSize: {
          width: 112,
          height: 112,
        },
        style: {
          width: 56,
          marginTrailing: 8,
          verticalAlignment: 'top',
        },
      },
      {
        type: STACK,
        id: uuid(),
        orientation: 'vertical',
        style: {
          horizontalAlignment: 'leading',
        },
        items: [
          {
            type: TEXT,
            id: uuid(),
            text: 'New feature',
            style: {
              fontName: 'System Default Semibold',
              foregroundColor: { light: '#000000' },
              textAlignment: 'leading',
              horizontalAlignment: 'leading',
            },
          },
          {
            type: TEXT,
            id: uuid(),
            text: 'Now you can customize your experience!',
            style: {
              foregroundColor: { light: '#000000' },
              textAlignment: 'leading',
              horizontalAlignment: 'leading',
            },
          },
        ],
      },
    ],
  },
});

export default imageWithTextTemplate;
