import { call, getContext, select, takeEvery } from 'redux-saga/effects';
import {
  selectExperience,
  patterns as patternsExperiences,
} from 'entities/experiences';
import { patterns as patternsStepGroups } from 'entities/step-groups';
import { patterns as patternsStepChildren } from 'entities/step-children';
import { selectSelected } from 'entities/selected';
import { isPreviewBuild } from './location';

function* setUrl({ experienceId, stepId }) {
  const history = yield getContext('history');
  const { pathname } = history.location;

  const prefixed = pathname.startsWith('/mobile');

  const path = [
    // Preserve the development prefix if it exists
    prefixed ? '/mobile' : '',
    // Set experience/flow ID in path
    `/flows/${experienceId}`,
    // Append step ID if provided
    stepId ? `/steps/${stepId}` : '',
  ].join('');

  // Every time we set a new URL, we want to track this page
  window?.analytics?.page('Mobile Builder');

  if (isPreviewBuild(pathname)) {
    window.location.hash = path;
  } else {
    yield call(history.replace, path);
  }
}

function* handleExperienceFocus({ payload: experienceId }) {
  yield setUrl({ experienceId });
}

function* handleExperienceCreate({ payload }) {
  // If the payload has stepGroups or stepChildren
  // it means we're undo/redoing, so we don't need to set the url
  if (!payload?.stepGroups && !payload?.stepChidren) {
    const { id } = yield select(selectExperience);
    yield call(setUrl, { experienceId: id });
  }
}

function* handleStepCreate({ payload }) {
  const { id: experienceId } = yield select(selectExperience);

  // If we're creating a step child, then use the ID in the payload directly.
  // Otherwise, assume we're creating a step group and use the ID of the first
  // step child.
  const stepId =
    payload.id || Object.values(payload.stepGroups)[0]?.children[0];

  yield call(setUrl, { experienceId, stepId });
}

function* handleStepDelete() {
  const { id: experienceId } = yield select(selectExperience);
  const selected = yield select(selectSelected);
  const stepId = selected?.stepChild;

  yield setUrl({ experienceId, stepId });
}

function* handleExperienceResolved({
  payload: { experience, stepGroups = {} },
}) {
  const selected = yield select(selectSelected);

  const [groupId] = experience.steps;
  const stepId = selected?.stepChild || stepGroups[groupId]?.children[0];

  yield call(setUrl, { experienceId: experience.id, stepId });
}

function* handleStepFocus({ payload: { id: stepId } }) {
  // anytime we focus a step, we must have already loaded an experience, so we can safely
  // grab the experience ID from state
  const { id: experienceId } = yield select(selectExperience);
  yield setUrl({ experienceId, stepId });
}

export default function* locationSaga() {
  yield takeEvery(patternsExperiences.focus, handleExperienceFocus);
  yield takeEvery(patternsExperiences.insert, handleExperienceCreate);
  yield takeEvery(patternsExperiences.resolve, handleExperienceResolved);
  yield takeEvery(patternsStepChildren.focus, handleStepFocus);

  yield takeEvery(
    [patternsStepGroups.insert, patternsStepChildren.insert],
    handleStepCreate
  );

  yield takeEvery(
    [patternsStepGroups.remove, patternsStepChildren.remove],
    handleStepDelete
  );
}
