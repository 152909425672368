/* global MOBILE_BUILDER_URL */

import React, { useMemo } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { Heading, Modal, ModalHeader } from 'ext/components/ui';
import useAnalytics from 'ext/lib/hooks/use-analytics';
import { DEFAULT_LOCALE } from 'lib/localization';
import { selectActiveLocale } from 'entities/locales';
import { isPreviewBuild } from 'lib/location';
import { Shape as ExperienceShape } from 'entities/experiences';
import { TYPES } from 'components/PreviewProxy';
import PreviewQRCode from 'components/PreviewQrCode';

export const PreviewMobileFlowModal = ({
  flow,
  accountId,
  onClose,
  visible,
  localeId,
}) => {
  const { track } = useAnalytics();

  const { appId, id } = flow;

  const previewUrl = useMemo(() => {
    const { pathname } = window.location;
    const hash = isPreviewBuild(pathname) ? `${pathname}#` : '';
    const locale =
      localeId === DEFAULT_LOCALE.id ? '' : `&localeId=${localeId}`;
    return `${MOBILE_BUILDER_URL}${hash}?type=${TYPES.PREVIEW}&appId=${appId}&accountId=${accountId}&experienceId=${id}${locale}`;
  }, [appId, accountId, id, localeId]);

  const onClick = () => {
    track('Mobile Builder view', {
      name: 'Clicked Done Preview Flow Modal',
      component: 'PreviewMobileFlowModal',
    });
    onClose();
  };

  return (
    <Modal onClose={onClose} visible={visible} theme="light">
      <ModalHeader>
        <Heading>Preview mobile Flow</Heading>
        <ModalHeader.Close onClose={onClose} />
      </ModalHeader>
      <PreviewQRCode previewUrl={previewUrl} onClick={onClick} />
    </Modal>
  );
};

PreviewMobileFlowModal.propTypes = {
  flow: ExperienceShape.isRequired,
  accountId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  localeId: PropTypes.string,
};

const mapStateToProps = state => {
  const { id: localeId } = selectActiveLocale(state) ?? {};

  return {
    localeId,
  };
};

const mapDispatchToProps = {};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PreviewMobileFlowModal);
