/**
 * Build menu options according the received parameters
 *
 * @param {object} account - Account object
 * @param {object} spoofing - Spoofing object
 * @param {function} logout - Logout function
 * @returns {array} - Menu items options within their categories
 */
export const menuOptionsHandler = ({ account, spoofing, logout }) => {
  const { accountId, isAdmin, hasMultipleAccounts, toggleAccounts } = account;
  const { isSpoofing, toggleSpoofing } = spoofing;

  return [
    {
      headerLabel: 'Account',
      items: [
        {
          icon: 'external-link-alt',
          label: 'Appcues Dashboard',
          href: `${STUDIO_URL}?account=${accountId}`,
        },
        hasMultipleAccounts && {
          icon: 'users',
          label: 'Switch accounts',
          callback: toggleAccounts,
        },
        isAdmin &&
          isSpoofing === false && {
            icon: 'user-secret',
            label: 'Spoof account',
            callback: toggleSpoofing,
          },
        {
          icon: 'sign-out-alt',
          label: 'Sign out',
          callback: logout,
        },
      ].filter(Boolean),
    },
    {
      headerLabel: 'Help',
      items: [
        {
          icon: 'info-circle',
          label: 'Help docs',
          href: 'https://docs.appcues.com/',
        },
        {
          icon: 'envelope',
          label: 'Contact support',
          href: 'mailto:support@appcues.com',
        },
        {
          icon: 'lightbulb',
          label: 'Flow inspiration',
          href: 'https://www.reallygoodux.io',
        },
        {
          icon: 'paper-plane',
          label: "What's new",
          href: 'https://appcues.com/whats-new',
        },
      ],
    },
  ];
};
