import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Accordion,
  FieldSet,
  FontIcon,
  Label,
  RadioButton,
  RadioButtonGroup,
  Select,
  Switch,
} from 'ext/components/ui';
import useAnalytics from 'ext/lib/hooks/use-analytics';
import {
  EditorShape,
  backdropTemplate,
  dropShadowTemplate,
  pagingDotsStyleTemplate,
  slideoutTemplate,
} from 'entities/step-groups';
import { MODAL, SKIPPABLE_OPTIONS } from 'lib/trait';
import { PRESENTATIONS, STANDARD, SLIDEOUT } from 'lib/presentation';
import { Controls, useAccordionClick } from 'components/SideBarSettings/Shared';
import { SurveyCarouselWarning } from 'components/SideBarSettings/Trait/SurveyCarouselWarning';

const PRESENTATION_OPTIONS = PRESENTATIONS[MODAL];

const ALIGNMENT = {
  top: 'top',
  center: 'center',
  bottom: 'bottom',
};

const { style: slideoutStyleTemplate, ...slideoutDetailsTemplate } =
  slideoutTemplate;

export default function Details({
  editor,
  isSingleStep,
  hasSurveyBlock,
  onChange,
}) {
  const { track } = useAnalytics();
  const trackAccordion = useAccordionClick();
  const { carousel, presentation, skippable, pagingDots, style } = editor ?? {};
  const verticalAlignment = style?.verticalAlignment || ALIGNMENT.center;

  const handleTrack = useCallback(
    name => {
      track('Mobile Builder interaction', {
        name,
        component: 'ModalSettings - Details',
      });
    },
    [track]
  );

  const options = useMemo(() => {
    const entries = Object.entries(PRESENTATION_OPTIONS);
    return entries.map(([value, label]) => ({ value, label }));
  }, []);

  const handlePresentationUpdate = useCallback(
    ({ value }) => {
      handleTrack(`Updated Presentation ${PRESENTATIONS[value]}`);

      const {
        borderColor,
        borderWidth,
        verticalAlignment: previousVerticalAlignment,
        horizontalAlignment: previousHorizontalAlignment,
        width,
        ...styleRest
      } = style || {};

      const baseDetailsTemplate = {
        slideout: false,
        ...(value === STANDARD && { transition: 'fade' }),
      };

      onChange({
        presentation: value,
        ...(value === SLIDEOUT ? slideoutDetailsTemplate : baseDetailsTemplate),
        style: {
          ...styleRest,
          ...(value !== STANDARD && { shadow: dropShadowTemplate }),
          ...(value === SLIDEOUT && slideoutStyleTemplate),
        },
        ...(value !== STANDARD && { backdrop: backdropTemplate.config }),
      });
    },
    [handleTrack, style, onChange]
  );

  const handleSkippableUpdate = useCallback(
    value => {
      const { DEFAULT, HIDDEN } = SKIPPABLE_OPTIONS;
      const buttonAppearance = value ? DEFAULT : HIDDEN;
      handleTrack(`${value ? 'Added' : 'Removed'} Modal close`);
      onChange({
        skippable: {
          ...skippable,
          ignoreBackdropTap: !value,
          buttonAppearance,
        },
      });
    },
    [onChange, skippable, handleTrack]
  );

  // Manage style object when adding or remove the entire pagingDots trait otherwise
  const handlePagingDotsUpdate = useCallback(() => {
    handleTrack(`${!pagingDots ? 'Added' : 'Removed'} Step indicator`);
    if (pagingDots) {
      onChange(null, 'pagingDots');
    } else {
      onChange({
        pagingDots: pagingDotsStyleTemplate,
      });
    }
  }, [onChange, pagingDots, handleTrack]);

  const handleCarouselUpdate = useCallback(
    value => {
      handleTrack(`${value ? 'Added' : 'Removed'} Swipe for next step`);
      onChange({ carousel: value });
    },
    [onChange, handleTrack]
  );

  const handleAlignmentUpdate = useCallback(
    value => {
      onChange({
        style: {
          ...style,
          verticalAlignment: value,
        },
      });
    },
    [style, onChange]
  );

  return (
    <Accordion.Item value="details">
      <Accordion.Header>
        <Accordion.Trigger onClick={() => trackAccordion('Modal', 'Details')}>
          Details
          <FontIcon size="sm" icon="chevron-down" />
        </Accordion.Trigger>
      </Accordion.Header>
      <Accordion.Content>
        <Controls>
          <FieldSet>
            <Label>Type</Label>
            <Select
              onChange={handlePresentationUpdate}
              options={options}
              portal
              value={options.find(({ value }) => presentation === value)}
            />
          </FieldSet>

          {presentation === STANDARD && (
            <FieldSet>
              <Label>Position</Label>
              <RadioButtonGroup>
                <RadioButton
                  onClick={() => handleAlignmentUpdate(ALIGNMENT.top)}
                  selected={verticalAlignment === ALIGNMENT.top}
                >
                  Top
                </RadioButton>
                <RadioButton
                  onClick={() => handleAlignmentUpdate(ALIGNMENT.center)}
                  selected={verticalAlignment === ALIGNMENT.center}
                >
                  Middle
                </RadioButton>
                <RadioButton
                  onClick={() => handleAlignmentUpdate(ALIGNMENT.bottom)}
                  selected={verticalAlignment === ALIGNMENT.bottom}
                >
                  Bottom
                </RadioButton>
              </RadioButtonGroup>
            </FieldSet>
          )}

          <FieldSet>
            <Switch
              label="Close X"
              checked={skippable?.buttonAppearance !== SKIPPABLE_OPTIONS.HIDDEN}
              onChange={handleSkippableUpdate}
            />
          </FieldSet>

          {!isSingleStep && (
            <>
              <FieldSet>
                <Switch
                  label="Step indicator"
                  checked={!!pagingDots}
                  onChange={handlePagingDotsUpdate}
                />
              </FieldSet>

              <FieldSet>
                <Switch
                  label="Swipe for next step"
                  checked={carousel}
                  onChange={handleCarouselUpdate}
                />
              </FieldSet>
              {hasSurveyBlock && carousel && <SurveyCarouselWarning />}
            </>
          )}
        </Controls>
      </Accordion.Content>
    </Accordion.Item>
  );
}

Details.propTypes = {
  editor: EditorShape,
  isSingleStep: PropTypes.bool,
  hasSurveyBlock: PropTypes.bool,
  onChange: PropTypes.func,
};
