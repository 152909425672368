import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectStepGroup, EditorShape } from 'entities/step-groups';
import { selectStepChild, ContentShape } from 'entities/step-children';
import { selectSelected } from 'entities/selected';
import { Shape as ExperienceShape } from 'entities/experiences';
import { Shape as BlockShape } from 'entities/block';
import { selectActiveLocale } from 'entities/locales';
import { isEmbedExperience } from 'lib/experiences';
import { TRAITS, LAYOUT_TRAITS, TRAIT_LABELS } from 'lib/trait';
import { localizeContent } from 'lib/localization';
import { selectBlockContent } from 'lib/selectors';
import { MODES } from 'hooks/use-mode';
import LightTheme from 'components/LightTheme';
import Presentations from 'components/Presentations';
import { BlockSettings, TraitSettings } from 'components/SideBarSettings';
import { getBlockSettings } from './get-block-settings';
import SideBar from './styled';

const MESSAGE_BAR_HEIGHT = 32;

export function SideBarManager({
  experience,
  isSpoofing,
  mode,
  selectedStepGroup,
  selectedStepChild,
  selectedTrait,
  selectedBlockContent,
  editor,
  layoutTrait,
  content,
  localeId,
}) {
  if (mode === MODES.empty) return null;

  const localizedContent = localizeContent(content, localeId);
  const localizedSelectedBlock = localizeContent(
    selectedBlockContent,
    localeId
  );

  // We can show more than one message bar at a time, so we need to calculate
  // what is the total size they are taking up to not overlap the sidebar.
  const isPublished = experience?.published;
  const messagesHeight = [isSpoofing, isPublished].reduce((height, isTrue) => {
    return isTrue ? height + MESSAGE_BAR_HEIGHT : height;
  }, 0);

  const { blockLabel = 'Block', ...blockSettings } = selectedBlockContent
    ? getBlockSettings(localizedContent, localizedSelectedBlock)
    : {};

  const { blockLabel: originalBlockLabel, ...blockSettingsWithVariations } =
    selectedBlockContent ? getBlockSettings(content, selectedBlockContent) : {};

  const traitLabel = TRAIT_LABELS[selectedTrait || layoutTrait];

  const handleTitle = () => {
    if (mode === MODES.edit)
      return selectedBlockContent ? blockLabel : traitLabel;
    if (isEmbedExperience(experience.type) && !selectedTrait) {
      return 'Start with a template';
    }
    return 'Add';
  };

  return (
    <LightTheme>
      <SideBar
        isBottomBarCollapsed={false}
        messagesHeight={messagesHeight}
        title={handleTitle()}
      >
        {mode === MODES.new && (
          <Presentations
            experience={{
              id: experience?.id,
              type: experience?.type,
              platform: experience?.platform,
            }}
          />
        )}

        {mode === MODES.edit && !selectedBlockContent && (
          <TraitSettings
            selectedStepGroup={selectedStepGroup}
            selectedStepChild={selectedStepChild}
            selectedTrait={selectedTrait}
            editor={editor}
            layoutTrait={layoutTrait}
          />
        )}

        {mode === MODES.edit && selectedBlockContent && (
          <BlockSettings
            selectedStepChild={selectedStepChild}
            blockSettings={blockSettings}
            blockSettingsWithVariations={blockSettingsWithVariations}
            content={content}
          />
        )}
      </SideBar>
    </LightTheme>
  );
}

SideBarManager.propTypes = {
  experience: ExperienceShape,
  isSpoofing: PropTypes.bool,
  mode: PropTypes.oneOf(Object.values(MODES)),
  selectedStepGroup: PropTypes.string,
  selectedStepChild: PropTypes.string,
  selectedTrait: PropTypes.oneOf(TRAITS),
  selectedBlockContent: BlockShape,
  editor: EditorShape,
  layoutTrait: PropTypes.oneOf(LAYOUT_TRAITS),
  content: ContentShape,
  localeId: PropTypes.string,
};

const mapStateToProps = state => {
  const {
    stepGroup: selectedStepGroup,
    stepChild: selectedStepChild,
    trait: selectedTrait,
  } = selectSelected(state) ?? {};
  const { editor } = selectStepGroup(state, selectedStepGroup) ?? {};
  const { type: layoutTrait } = editor ?? {};
  const { content } = selectStepChild(state, selectedStepChild) ?? {};
  const selectedBlockContent = selectBlockContent(state);
  const { id: localeId } = selectActiveLocale(state) ?? {};

  return {
    selectedStepGroup,
    selectedStepChild,
    selectedTrait,
    selectedBlockContent,
    editor,
    layoutTrait,
    content,
    localeId,
  };
};

export default connect(mapStateToProps)(SideBarManager);
