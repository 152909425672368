import {
  STANDARD,
  HALF_SCREEN,
  FULL_SCREEN,
  COVER_SHEET,
  SLIDEOUT,
  ANCHORED,
  FLOATING,
  ANNOUNCEMENT,
  PROMOTION,
  SURVEY,
  CTA,
  EMPTY,
} from 'lib/presentation';
import { updateToLocalized } from 'lib/localization';
import { generateModalStep } from './generate-modal-step';
import { generateSlideoutStep } from './generate-slideout-step';
import { generateTooltipStep } from './generate-tooltip-step';
import { generateEmbedPromotionStep } from './generate-embed-promotion-step';
import { generateEmbedAnnouncementStep } from './generate-embed-announcement-step';
import { generateEmbedSurveyStep } from './generate-embed-survey-step';
import { generateEmbedEmptyStep } from './generate-embed-empty-step';
import { generateEmbedCTAStep } from './generate-embed-cta-step';

export const generateStep = ({
  presentation,
  locales,
  singleStep,
  previewStepId,
}) => {
  const stepGenerators = {
    [STANDARD]: generateModalStep,
    [HALF_SCREEN]: generateModalStep,
    [FULL_SCREEN]: generateModalStep,
    [COVER_SHEET]: generateModalStep,
    [SLIDEOUT]: generateSlideoutStep,
    [FLOATING]: generateTooltipStep,
    [ANCHORED]: generateTooltipStep,
    [EMPTY]: generateEmbedEmptyStep,
    [ANNOUNCEMENT]: generateEmbedAnnouncementStep,
    [PROMOTION]: generateEmbedPromotionStep,
    [SURVEY]: generateEmbedSurveyStep,
    [CTA]: generateEmbedCTAStep,
  };

  let step =
    stepGenerators[presentation]?.({
      presentation,
      locales,
      singleStep,
      previewStepId,
    }) ?? {};

  if (locales?.length > 0) {
    const localizedContent = updateToLocalized(step.content, locales);
    step = {
      ...step,
      content: localizedContent,
    };
  }

  return step;
};
