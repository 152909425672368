import PropTypes from 'prop-types';
import { PREVIEW_TYPES } from 'lib/user-interface';

export default PropTypes.shape({
  preview: PropTypes.shape({
    id: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.oneOf(PREVIEW_TYPES),
        show: PropTypes.bool,
      })
    ),
  }),
  targetPlacement: PropTypes.bool,
  selectedSideBarTab: PropTypes.shape({
    stepGroup: PropTypes.string,
  }),
});
