import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { update } from 'entities/step-children';
import { TARGET_INTERACTION } from 'lib/trait';
import { Shape as ActionsShape } from 'lib/actions';
import { Actions, actionsMapper } from 'components/SideBarSettings/Shared';

export function TargetAction({ targetInteraction, handleTraitsUpdate }) {
  const { actions: prevActions = [] } = targetInteraction?.config ?? {};

  const handleActionsUpdate = newActions => {
    const updatedTargetInteraction = {
      ...targetInteraction,
      config: {
        ...targetInteraction?.config,
        actions: actionsMapper({ prevActions, newActions }),
      },
    };

    handleTraitsUpdate(updatedTargetInteraction);
  };

  return (
    <Actions
      actions={prevActions}
      context={TARGET_INTERACTION}
      onActionsChange={handleActionsUpdate}
    />
  );
}

TargetAction.propTypes = {
  targetInteraction: PropTypes.shape({
    type: PropTypes.oneOf([TARGET_INTERACTION]),
    config: PropTypes.shape({
      actions: PropTypes.arrayOf(ActionsShape),
    }),
  }),
  handleTraitsUpdate: PropTypes.func,
};

const mapDispatchToProps = {
  onUpdate: update,
};

export default connect(null, mapDispatchToProps)(TargetAction);
