import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { del } from 'object-path-immutable';
import { Icon } from '@appcues/sonar';
import { faPalette } from '@fortawesome/pro-solid-svg-icons/faPalette';
import { faImage } from '@fortawesome/pro-solid-svg-icons/faImage';
import {
  FieldSet,
  Label,
  RadioButton,
  RadioButtonGroup,
} from 'ext/components/ui';
import { selectAccountId } from 'ext/entities/account';
import {
  update as updateStepGroup,
  EditorShape,
  backgroundContentTemplate,
} from 'entities/step-groups';
import { selectSelected, Shape as SelectedShape } from 'entities/selected';
import { TRAIT_LABELS } from 'lib/trait';
import ColorInput from 'components/ColorInput';
import ImageUploader from 'components/ImageUploader';
import AlignmentPicker from 'components/AlignmentPicker';
import { RadioButtonLabel } from './styled';

const MODES = {
  color: 'color',
  image: 'image',
};

const Background = ({
  accountId,
  editor,
  selected,
  onGroupUpdate,
  valueFor,
  handleChangeFor,
}) => {
  const { backgroundContent = {}, type } = editor;
  const traitLabel = TRAIT_LABELS[type];
  const { style = {} } = backgroundContent;
  const [editMode, setEditMode] = useState(MODES.color);
  const [previousBackgroundColor, setPreviousBackgroundColor] = useState({
    light: '#FFFFFF',
  });
  const [previousBackgroundImage, setPreviousBackgroundImage] = useState({
    ...backgroundContentTemplate().config.content,
  });

  useEffect(() => {
    setEditMode(style?.backgroundImage?.imageUrl ? MODES.image : MODES.color);
  }, [style]);

  const handleChange = useCallback(
    (patch, traitToDelete) => {
      const updatedEditor = traitToDelete ? del(editor, traitToDelete) : editor;
      const merge = { ...updatedEditor, ...patch };
      onGroupUpdate(selected.stepGroup, { editor: merge });
    },
    [editor, onGroupUpdate, selected]
  );

  const handleColorMode = () => {
    if (editMode !== MODES.color) {
      setEditMode(MODES.color);
      setPreviousBackgroundImage(editor.backgroundContent);
      handleChange(
        {
          style: {
            ...editor.style,
            backgroundColor: previousBackgroundColor,
          },
        },
        'backgroundContent'
      );
    }
  };

  const handleBackgroundImageChange = () => {
    if (editMode !== MODES.image) {
      setEditMode(MODES.image);
      setPreviousBackgroundColor(editor.style.backgroundColor);
      handleChange({ backgroundContent: previousBackgroundImage });
    }
  };

  const handleBackgroundImage = useCallback(
    ({
      url: imageUrl,
      intrinsicSize,
      horizontalAlignment,
      verticalAlignment,
    }) => {
      // Enforce the transparent background color when uploading
      // a image that could have transparent color
      handleChange({
        style: {
          ...editor?.style,
          backgroundColor: { light: '#FFFFFF00', dark: '#FFFFFF00' },
        },
        backgroundContent: {
          ...backgroundContent,
          style: {
            backgroundImage: {
              ...style?.backgroundImage,
              ...(imageUrl && { imageUrl }),
              ...(intrinsicSize && { intrinsicSize }),
              ...(horizontalAlignment && { horizontalAlignment }),
              ...(verticalAlignment && { verticalAlignment }),
            },
          },
        },
      });
    },
    [handleChange, backgroundContent, style, editor]
  );

  const handleAlignment = {
    horizontalAlignment: horizontalAlignment =>
      handleBackgroundImage({ horizontalAlignment }),
    verticalAlignment: verticalAlignment =>
      handleBackgroundImage({ verticalAlignment }),
  };

  return (
    <>
      <FieldSet>
        <Label htmlFor="trait-background-color-input">
          {traitLabel} background
        </Label>
        <RadioButtonGroup>
          <RadioButton
            onClick={handleColorMode}
            selected={editMode === MODES.color}
          >
            <RadioButtonLabel>
              <Icon icon={faPalette} />
              Color
            </RadioButtonLabel>
          </RadioButton>
          <RadioButton
            onClick={handleBackgroundImageChange}
            selected={editMode === MODES.image}
          >
            <RadioButtonLabel>
              <Icon icon={faImage} />
              Image
            </RadioButtonLabel>
          </RadioButton>
        </RadioButtonGroup>
      </FieldSet>

      {editMode === MODES.color && (
        <FieldSet>
          <ColorInput
            id="trait-background-color-input"
            color={valueFor.backgroundColor}
            onChange={handleChangeFor.backgroundColor}
          />
        </FieldSet>
      )}

      {editMode === MODES.image && (
        <>
          <FieldSet>
            <ImageUploader
              accountId={accountId}
              onUpload={handleBackgroundImage}
            />
          </FieldSet>
          <FieldSet>
            <AlignmentPicker
              alignments={[
                style?.backgroundImage?.horizontalAlignment,
                style?.backgroundImage?.verticalAlignment,
              ]}
              onClick={handleAlignment}
            />
          </FieldSet>
        </>
      )}
    </>
  );
};

Background.propTypes = {
  accountId: PropTypes.string,
  editor: EditorShape,
  onGroupUpdate: PropTypes.func,
  selected: SelectedShape,
  valueFor: PropTypes.shape({
    backgroundColor: PropTypes.string,
  }),
  handleChangeFor: PropTypes.shape({
    backgroundColor: PropTypes.func,
  }),
};

const mapStateToProps = state => {
  const accountId = selectAccountId(state);
  const selected = selectSelected(state);

  return {
    accountId,
    selected,
  };
};

const mapDispatchToProps = {
  onGroupUpdate: updateStepGroup,
};

export default connect(mapStateToProps, mapDispatchToProps)(Background);
