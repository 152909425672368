import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { isValidURl, isValidInAppUrl } from 'ext/lib/url';
import { FieldSet, Switch } from 'ext/components/ui';
import { getLink, getClose } from 'lib/actions';
import { InputUrl } from 'components/SideBarSettings/Shared';

export default function GoToUrlOption({ config, onChange }) {
  const { url, openExternally = false, markComplete = false } = config || {};
  const [currentUrl, setCurrentUrl] = useState('');
  const [isInAppUrl, setIsInAppUrl] = useState(true);
  const [isValidUrl, setIsValidUrl] = useState(true);

  useEffect(() => {
    setCurrentUrl(url);
    setIsInAppUrl(isValidInAppUrl(url));
    setIsValidUrl(isValidURl(url));
  }, [url]);

  const handleChange = ({
    urlValue = currentUrl,
    openExternallyValue = openExternally,
    markCompleteValue = markComplete,
  } = {}) => {
    const updatedOpenExternally = isValidInAppUrl(urlValue)
      ? false
      : openExternallyValue;
    const actions = [
      getLink({ url: urlValue, openExternally: updatedOpenExternally }),
    ];

    // We always put the close at first position
    // so the SDK won't have issues with race conditions
    if (markCompleteValue) {
      actions.unshift(getClose({ markComplete: true }));
    }

    onChange({ actions });
  };

  const handleUrlChange = ({ target: { value } }) => {
    setCurrentUrl(value);

    const isValidInAppUrlLink = isValidInAppUrl(value);
    setIsInAppUrl(isValidInAppUrlLink);

    const isValidUrlLink = isValidURl(value);
    setIsValidUrl(isValidUrlLink);

    if (isValidInAppUrlLink || isValidUrlLink) {
      handleChange({ urlValue: value });
    }
  };

  const handleOpenExternallyChange = checked => {
    handleChange({ openExternallyValue: checked });
  };

  const handleMarkCompleteChange = checked => {
    handleChange({ markCompleteValue: checked });
  };

  return (
    <>
      <InputUrl
        url={currentUrl}
        onChange={handleUrlChange}
        isValidUrl={isValidUrl || isInAppUrl}
      />

      <FieldSet>
        <Switch
          label="Open in external browser"
          disabled={isInAppUrl}
          checked={!isInAppUrl && openExternally}
          onChange={handleOpenExternallyChange}
        />
      </FieldSet>

      <FieldSet>
        <Switch
          label="Mark flow complete"
          checked={markComplete}
          onChange={handleMarkCompleteChange}
        />
      </FieldSet>
    </>
  );
}

GoToUrlOption.propTypes = {
  config: PropTypes.shape({
    url: PropTypes.string,
    openExternally: PropTypes.bool,
    markComplete: PropTypes.bool,
  }),
  onChange: PropTypes.func,
};
