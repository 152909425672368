import { v4 as uuid } from 'uuid';
import { TEXT, STACK } from 'lib/block';
import generateElementTemplate from 'components/Editor/Templates/generate-element-template';

/**
 * Create canned embed announcement step
 *
 * @param {string} previewStepId - id to preview the step template before creation
 * @return {Step} Canned step group
 */
export const generateEmbedAnnouncementStep = ({ previewStepId }) => {
  return {
    ...(previewStepId && { id: previewStepId }),
    type: 'embed',
    contentType: 'application/json',
    content: {
      id: uuid(),
      type: STACK,
      orientation: 'vertical',
      style: {},
      items: [
        generateElementTemplate({
          template: TEXT,
          blockId: uuid(),
          elementId: uuid(),
          newRow: true,
          content: {
            text: 'Scheduled maintenance from 22:00-23:00 UTC',
            style: {
              fontSize: 14,
              fontName: 'System Default Bold',
              foregroundColor: { light: '#0B1A38' },
              textAlignment: 'leading',
              horizontalAlignment: 'leading',
            },
          },
        }),
      ],
    },
    actions: {},
  };
};
