import React, { useState } from 'react';
import { transform } from 'entities/experiences';
import Editor from 'components/Editor/Editor';
import PreviewProvider from './PreviewProvider';

export default function PreviewExperience() {
  const [editorValue, setEditorValue] = useState();
  const [contentValue, setContentValue] = useState();
  const [traitsValue, setTraitsValue] = useState();
  const [stepChildrenStore, setStepChildren] = useState();
  const [stepChild, setStepChild] = useState();

  // This is an exposed method so we can render/test different previews
  // even outside of Puppeteer (image-service)
  window.apc_set_mobile_experience = (experience, stepId) => {
    const { stepChildren, stepGroups } = transform(experience);
    const { content, traits } = stepChildren[stepId] || {};

    const stepGroupId = Object.keys(stepGroups).find(section => {
      return stepGroups[section].children.includes(stepId);
    });

    const { editor } = stepGroups[stepGroupId] || {};

    setStepChild(stepId);
    setStepChildren(stepChildren);
    setContentValue(content);
    setTraitsValue(traits);
    setEditorValue(editor);
  };

  return contentValue && editorValue ? (
    <PreviewProvider stepChildren={stepChildrenStore} stepChild={stepChild}>
      <Editor
        editor={editorValue}
        content={contentValue}
        traits={traitsValue}
        isImageService
      />
    </PreviewProvider>
  ) : null;
}
