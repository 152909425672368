import { v4 as uuid } from 'uuid';
import { getContinue, getSubmitForm } from 'lib/actions';
import { TEXT, TEXT_INPUT, BUTTON, STACK, RATING_EMOJI } from 'lib/block';
import { generateElementTemplate } from 'components/Editor/Templates';

/**
 * Create canned embed survey step
 *
 * @param {string} previewStepId - id to preview the step template before creation
 * @return {Step} Canned step group
 */
export const generateEmbedSurveyStep = ({ singleStep, previewStepId }) => {
  const firstButtonElementId = uuid();
  const secondButtonElementId = uuid();

  const steps = [
    {
      ...(previewStepId && { id: previewStepId }),
      type: 'embed',
      contentType: 'application/json',
      content: {
        id: uuid(),
        type: STACK,
        orientation: 'vertical',
        style: {},
        items: [
          generateElementTemplate({
            template: RATING_EMOJI,
            blockId: uuid(),
            elementId: uuid(),
            newRow: true,
          }),
          generateElementTemplate({
            template: BUTTON,
            blockId: uuid(),
            elementId: firstButtonElementId,
            newRow: true,
            content: generateElementTemplate({
              template: TEXT,
              blockId: uuid(),
              elementId: uuid(),
              content: {
                text: 'Submit',
                style: {
                  fontSize: 17,
                  marginTop: 0,
                  marginBottom: 0,
                  marginLeading: 0,
                  marginTrailing: 0,
                  foregroundColor: { light: '#5C5CFF' },
                },
              },
            }),
            style: {
              backgroundColor: { light: '#FFFFFF' },
              borderColor: { light: '#5c5cff' },
              borderWidth: 2,
              marginLeading: 24,
              marginTrailing: 24,
              paddingTop: 16,
              paddingBottom: 16,
              width: -1,
            },
          }),
        ],
      },
      actions: {
        [firstButtonElementId]: [getSubmitForm(), getContinue()],
      },
    },
    {
      type: 'embed',
      contentType: 'application/json',
      content: {
        id: uuid(),
        type: STACK,
        orientation: 'vertical',
        style: {},
        items: [
          generateElementTemplate({
            template: TEXT_INPUT,
            blockId: uuid(),
            elementId: uuid(),
            newRow: true,
            content: {
              label: {
                type: TEXT,
                id: uuid(),
                text: 'Please tell us more',
                style: {
                  textAlignment: 'leading',
                  marginBottom: 12,
                  fontName: 'System Default Bold',
                  fontSize: 17,
                  foregroundColor: { light: '#425678' },
                  horizontalAlignment: 'leading',
                },
              },
              placeholder: {
                type: TEXT,
                id: uuid(),
                text: 'Feedback (Optional)',
                style: {
                  foregroundColor: { light: '#627293' },
                },
              },
              numberOfLines: 3,
            },
          }),
          generateElementTemplate({
            template: BUTTON,
            blockId: uuid(),
            elementId: firstButtonElementId,
            newRow: true,
            content: generateElementTemplate({
              template: TEXT,
              blockId: uuid(),
              elementId: uuid(),
              content: {
                text: 'Submit',
                style: {
                  fontSize: 17,
                  marginTop: 0,
                  marginBottom: 0,
                  marginLeading: 0,
                  marginTrailing: 0,
                  foregroundColor: { light: '#5C5CFF' },
                },
              },
            }),
            style: {
              backgroundColor: { light: '#FFFFFF' },
              borderColor: { light: '#5c5cff' },
              borderWidth: 2,
              marginLeading: 24,
              marginTrailing: 24,
              paddingTop: 16,
              paddingBottom: 16,
              width: -1,
            },
          }),
        ],
      },
      actions: {
        [secondButtonElementId]: [getSubmitForm(), getContinue()],
      },
    },
  ];

  return singleStep ? steps[0] : steps;
};
