import { v4 as uuid } from 'uuid';
import { BLOCK, HERO, STACK, TEXT } from 'lib/block';

export const heroBackgroundTemplate = {
  backgroundImage: {
    imageUrl:
      'https://res.cloudinary.com/dnjrorsut/image/upload/v1658371397/mobile-builder/default-hero.jpg',
    blurHash: 'L33gHsuhOlM{D4aKVti}VwVa$ftR',
    intrinsicSize: {
      width: 1920,
      height: 1080,
    },
    horizontalAlignment: 'center',
    verticalAlignment: 'center',
    contentMode: 'fill',
  },
};

const heroTemplate = ({ blockId, elementId }) => ({
  type: BLOCK,
  blockType: HERO,
  id: blockId,
  content: {
    type: STACK,
    id: elementId,
    orientation: 'vertical',
    style: {
      backgroundColor: { light: '#5C5CFF' },
      ...heroBackgroundTemplate,
      width: -1,
    },
    items: [
      {
        type: STACK,
        id: uuid(),
        orientation: 'horizontal',
        distribution: 'equal',
        items: [
          {
            type: TEXT,
            id: uuid(),
            text: 'Ready to make your workflow simpler?',
            style: {
              fontName: 'System Default Bold',
              fontSize: 28,
              textAlignment: 'center',
              foregroundColor: { light: '#FFFFFF' },
              marginTop: 30,
              marginBottom: 15,
              marginLeading: 15,
              marginTrailing: 15,
            },
          },
        ],
      },
      {
        type: STACK,
        id: uuid(),
        orientation: 'horizontal',
        distribution: 'equal',
        items: [
          {
            type: TEXT,
            id: uuid(),
            text: 'Take a few moments to learn how to best use our features.',
            style: {
              fontName: 'System Default Medium',
              fontSize: 22,
              textAlignment: 'center',
              foregroundColor: { light: '#FFFFFF' },
              marginBottom: 30,
              marginLeading: 15,
              marginTrailing: 15,
            },
          },
        ],
      },
    ],
  },
});

export default heroTemplate;
