import { SKIPPABLE } from 'lib/trait';
import { ANNOUNCEMENT, CTA } from 'lib/presentation/presentation';

export const skippableTemplate = (buttonAppearance, presentation) => {
  const isAnnouncement = presentation === ANNOUNCEMENT;
  const isCTA = presentation === CTA;

  const announcementStyles = {
    buttonStyle: {
      marginTop: 2,
      marginLeading: 2,
      marginBottom: 2,
      marginTrailing: 2,
    },
  };

  const ctaStyles = {
    buttonStyle: {
      foregroundColor: { light: '#FFFFFF' },
    },
  };

  return {
    type: SKIPPABLE,
    ...(buttonAppearance && {
      config: {
        buttonAppearance,
        ...(isAnnouncement && announcementStyles),
        ...(isCTA && ctaStyles),
      },
    }),
  };
};
