import styled from 'styled-components';
import {
  Button,
  Text as SonarText,
  TileGroup as SonarTileGroup,
} from '@appcues/sonar';
import { easing } from 'ext/lib/style/easing';

export const Wrapper = styled.div`
  padding: 28px;
  display: flex;
  height: 100%;
  flex-direction: column;
  justify-content: space-between;
  overflow: auto;
`;

export const Text = styled(SonarText)`
  color: var(--color-neutral-600);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-bold);
`;

export const PresentationGroups = styled.div``;

export const ToggleGroupWrapper = styled.div`
  margin-top: 8px;
  margin-bottom: 16px;
`;

export const TileGroup = styled(SonarTileGroup)`
  && {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
`;

export const ActionsGroup = styled.div`
  gap: 16px;

  ${Button} {
    width: 100%;
    margin-bottom: 16px;
  }
`;

export const PreviewPane = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: var(--bottom-bar-height);
  width: calc(100vw - var(--side-bar-width));
  background: rgba(0, 0, 0, 0.5);
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  pointer-events: none;
  transition: ${easing('opacity')};
  z-index: -1;
`;
