import React, { useCallback } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { selectAccountId } from 'ext/entities/account';
import { FieldSet } from 'ext/components/ui';
import { Shape as BlockContentShape } from 'entities/block';
import ImageUploader from 'components/ImageUploader';
import { Controls } from 'components/SideBarSettings/Shared';

function ImageUpload({ content, onChange, accountId }) {
  const { id } = content ?? {};

  const handleUpload = useCallback(
    ({ url, intrinsicSize }) => {
      onChange({
        blockId: id,
        contentChunk: { imageUrl: url, blurHash: '', intrinsicSize },
      });
    },
    [id, onChange]
  );

  return (
    <Controls>
      <FieldSet>
        <ImageUploader onUpload={handleUpload} accountId={accountId} />
      </FieldSet>
    </Controls>
  );
}

ImageUpload.propTypes = {
  accountId: PropTypes.string,
  content: BlockContentShape,
  onChange: PropTypes.func,
};

const mapStateToProps = state => {
  return {
    accountId: selectAccountId(state),
  };
};

export default connect(mapStateToProps, null)(ImageUpload);
