import {
  BLOCK,
  TEXT,
  EMOJI,
  TEXT_INPUT,
  OPTION_SELECT,
  PLACEHOLDER,
  BLOCK_LABELS,
  SURVEY_BLOCK_LABELS,
  getElement,
  getParentBlock,
} from 'lib/block';

const handleBlockLabel = type =>
  SURVEY_BLOCK_LABELS[type] || BLOCK_LABELS[type];

/**
 * Get block settings including category and label
 * @param {object} content - root content of an step children
 * @param {object} block - selected block
 * @return {object} Block settings
 */
export const getBlockSettings = (content, block) => {
  /**
   * Blocks can be composed (a block created with two or more primitive blocks).
   * Styles are applied on the primitive blocks, so if the block is composed
   * we need to extract the primitive and apply the style to it.
   */
  const { id, type, blockType, content: blockContent } = block ?? {};
  const primitiveBlock = type === BLOCK ? { ...blockContent } : block;
  const { type: primitiveType } = primitiveBlock;

  // In case the primitive block has TEXT type and behaves like PLACEHOLDER
  // inside the TEXT_INPUT, we apply the style over it's composed block
  if (primitiveType === TEXT) {
    const { id: parentId } = getParentBlock(content, id) ?? {};
    const composedBlock = getElement(content, parentId);
    const {
      id: composedBlockId,
      content: composedBlockContent,
      blockType: composedBlockType,
    } = composedBlock;

    if (composedBlockType === EMOJI) {
      return {
        id,
        primitiveBlock,
        primitiveType,
        blockType: composedBlockType,
        blockCategory: blockType || primitiveType,
        blockLabel: handleBlockLabel(composedBlockType),
      };
    }

    // We should get the id from outside and also from inside content
    // in cases we have localized blocks
    const isOptions = composedBlockContent?.options
      ?.flatMap(({ content: contentOptions }) =>
        [contentOptions.id, contentOptions?.content?.id].filter(Boolean)
      )
      .includes(id);

    const isPlaceholder = composedBlockContent[PLACEHOLDER]?.id === id;

    if (isOptions || isPlaceholder) {
      const composedType = isOptions ? OPTION_SELECT : TEXT_INPUT;

      return {
        id: composedBlockId,
        primitiveBlock: composedBlockContent,
        primitiveType: composedType,
        blockCategory: composedType,
        blockLabel: handleBlockLabel(composedType),
      };
    }
  }

  /**
   * Blocks can be categorized by content (visual) or survey (forms).
   * The block category is defined by the precedence of the
   * block type (highest level in the block schema if it exists)
   * and then the primitive type (blocks used in main block composition).
   */
  const blockCategory = blockType || primitiveType;

  return {
    id,
    primitiveBlock,
    primitiveType,
    blockType,
    blockCategory,
    blockLabel: handleBlockLabel(blockType || type),
  };
};
