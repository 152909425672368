import styled, { css } from 'styled-components';
import { Input } from '@appcues/sonar';
import { FieldSet } from 'ext/components/ui';
import { Error as FormError } from 'components/Form';

export const FieldSetHelpLabel = styled(FieldSet)`
  display: flex;
  align-items: center;
  position: relative;

  > span {
    position: absolute;
    left: 70px;
  }
`;

export const Validation = styled.div`
  && ${Input} {
    ${({ error }) => error && 'border-color: var(--border-input-error);'}
  }
`;

export const Error = styled(FormError)`
  ${({ isRangeInput }) =>
    isRangeInput &&
    css`
      display: block;
      width: 100px;
      margin: 4px 0 0 auto;
    `};
`;
