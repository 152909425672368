import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ErrorBoundary from 'ext/components/ErrorBoundary';
import { selectAccountId } from 'ext/entities/account';
import { selectRedirecting, selectSpoofing } from 'entities/auth';
import {
  selectExperience,
  Shape as ExperienceShape,
} from 'entities/experiences';
import { selectSelected, Shape as SelectedShape } from 'entities/selected';
import useMode, { MODES } from 'hooks/use-mode';
import Editor from './Editor';
import TopMenuManager from './TopMenuManager';
import SideBarManager from './SideBarManager';
import ManagerBar from './ManagerBar';

export function App({
  accountId,
  experience,
  isRedirecting,
  isSpoofing,
  selected,
}) {
  const mode = useMode({ experience, selected });

  // If the builder is redirecting on load due to a forced account spoof or
  // switch, render nothing to prevent FOUC
  if (isRedirecting == null || isRedirecting === true) {
    return null;
  }

  return (
    <ErrorBoundary reload={window.reload}>
      {mode === MODES.edit && <TopMenuManager />}

      <>
        {mode === MODES.edit && <Editor />}

        <SideBarManager
          experience={experience}
          isSpoofing={isSpoofing}
          mode={mode}
        />

        <ManagerBar
          accountId={accountId}
          experience={experience}
          isSpoofing={isSpoofing}
          mode={mode}
          selected={selected}
        />
      </>
    </ErrorBoundary>
  );
}

App.propTypes = {
  accountId: PropTypes.string,
  experience: ExperienceShape,
  isRedirecting: PropTypes.bool,
  isSpoofing: PropTypes.bool,
  selected: SelectedShape,
};

const mapStateToProps = state => ({
  accountId: selectAccountId(state),
  experience: selectExperience(state),
  isRedirecting: selectRedirecting(state),
  isSpoofing: selectSpoofing(state),
  selected: selectSelected(state),
});

export default connect(mapStateToProps)(App);
