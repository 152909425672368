/* globals CLOUDINARY_UPLOAD_URL, CLOUDINARY_API_KEY */

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Button, Icon } from '@appcues/sonar';
import { faUpload } from '@fortawesome/free-solid-svg-icons/faUpload';
import { Error } from 'components/Form';
import { parseSvgFile } from './image-upload';

const MAX_FILE_SIZE = 5000000; // 5Mb

const InputFile = styled.input`
  display: none;
`;

const ImageRecommendation = styled.p`
  color: var(--color-neutral-700);
  font-size: var(--font-size-small);
  margin-bottom: 0;
`;

const ImageUploader = ({ onUpload, accountId }) => {
  const inputFileRef = useRef(null);
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState(null);

  const uploadImageFile = async originalFile => {
    const file = await parseSvgFile(originalFile);

    const data = new FormData();
    data.append('file', file);
    data.append('api_key', CLOUDINARY_API_KEY);
    data.append('timestamp', Math.trunc(Date.now() / 1000));
    data.append('folder', 'mobile-builder');
    data.append('folder', accountId);
    data.append('tags', [`account-${accountId}`, 'mobile'].join(','));

    // Transforming GIFs can have unintended consequences for Cloudinary,
    // like causing the GIF to become to large if it's scaled up, or dropping
    // frames if a transformation doesn't support the full resolution.
    const uploadPreset =
      file && file.type === 'image/gif' ? 'gif_preset' : 'sdruqnxi';
    data.append('upload_preset', uploadPreset);

    setIsUploading(true);
    setError(null);

    const upload = await fetch(CLOUDINARY_UPLOAD_URL, {
      method: 'POST',
      body: data,
    });

    const {
      error: imageError,
      secure_url: url,
      width,
      height,
    } = await upload.json();
    setIsUploading(false);

    if (imageError) {
      setError(imageError.message);
    }

    if (url) {
      onUpload({
        url,
        intrinsicSize: {
          width,
          height,
        },
      });
    }
  };

  const handleFileChange = e => {
    const { files } = e.target;

    if (files.length > 0) {
      const file = files[0];

      if (file.size > MAX_FILE_SIZE) {
        setError('Image must be smaller than 5MB');
        return;
      }

      uploadImageFile(file);
    }
  };

  return (
    <>
      <InputFile
        ref={inputFileRef}
        type="file"
        onChange={handleFileChange}
        data-testid="file-image-input"
      />
      <Button
        variant="secondary"
        onClick={() => inputFileRef.current.click()}
        loading={isUploading}
        fluid
      >
        <Icon icon={faUpload} />
        Upload image or .svg
      </Button>
      {!error && (
        <ImageRecommendation>
          Use images smaller than 1MB for better user experience
        </ImageRecommendation>
      )}
      {error && <Error>{error}</Error>}
    </>
  );
};

ImageUploader.propTypes = {
  accountId: PropTypes.string,
  onUpload: PropTypes.func,
};

export default ImageUploader;
