/**
 *  Languages options
 *
 * @constant
 */
export const LANGUAGES = [
  {
    id: 'ab',
    label: 'Abkhaz',
  },
  {
    id: 'aa',
    label: 'Afar',
  },
  {
    id: 'af',
    label: 'Afrikaans',
  },
  {
    id: 'ak',
    label: 'Akan',
  },
  {
    id: 'sq',
    label: 'Albanian',
  },
  {
    id: 'am',
    label: 'Amharic',
  },
  {
    id: 'ar',
    label: 'Arabic',
  },
  {
    id: 'ar-dz',
    label: 'Arabic - Algeria',
  },
  {
    id: 'ar-bh',
    label: 'Arabic - Bahrain',
  },
  {
    id: 'ar-eg',
    label: 'Arabic - Egypt',
  },
  {
    id: 'ar-iq',
    label: 'Arabic - Iraq',
  },
  {
    id: 'ar-jo',
    label: 'Arabic - Jordan',
  },
  {
    id: 'ar-kw',
    label: 'Arabic - Kuwait',
  },
  {
    id: 'ar-lb',
    label: 'Arabic - Lebanon',
  },
  {
    id: 'ar-ly',
    label: 'Arabic - Libya',
  },
  {
    id: 'ar-ma',
    label: 'Arabic - Morocco',
  },
  {
    id: 'ar-om',
    label: 'Arabic - Oman',
  },
  {
    id: 'ar-qa',
    label: 'Arabic - Qatar',
  },
  {
    id: 'ar-sa',
    label: 'Arabic - Saudi Arabia',
  },
  {
    id: 'ar-sy',
    label: 'Arabic - Syria',
  },
  {
    id: 'ar-tn',
    label: 'Arabic - Tunisia',
  },
  {
    id: 'ar-ae',
    label: 'Arabic - United Arab Emirates',
  },
  {
    id: 'ar-ye',
    label: 'Arabic - Yemen',
  },
  {
    id: 'an',
    label: 'Aragonese',
  },
  {
    id: 'hy',
    label: 'Armenian',
  },
  {
    id: 'as',
    label: 'Assamese',
  },
  {
    id: 'av',
    label: 'Avaric',
  },
  {
    id: 'ae',
    label: 'Avestan',
  },
  {
    id: 'ay',
    label: 'Aymara',
  },
  {
    id: 'az',
    label: 'Azerbaijani',
  },
  {
    id: 'az-az',
    label: 'Azeri - Cyrillic',
  },
  {
    id: 'az-az',
    label: 'Azeri - Latin',
  },
  {
    id: 'bm',
    label: 'Bambara',
  },
  {
    id: 'ba',
    label: 'Bashkir',
  },
  {
    id: 'eu',
    label: 'Basque',
  },
  {
    id: 'be',
    label: 'Belarusian',
  },
  {
    id: 'bn',
    label: 'Bengali, Bangla',
  },
  {
    id: 'bh',
    label: 'Bihari',
  },
  {
    id: 'bi',
    label: 'Bislama',
  },
  {
    id: 'bs',
    label: 'Bosnian',
  },
  {
    id: 'br',
    label: 'Breton',
  },
  {
    id: 'bg',
    label: 'Bulgarian',
  },
  {
    id: 'my',
    label: 'Burmese',
  },
  {
    id: 'ca',
    label: 'Catalan',
  },
  {
    id: 'ch',
    label: 'Chamorro',
  },
  {
    id: 'ce',
    label: 'Chechen',
  },
  {
    id: 'ny',
    label: 'Chichewa, Chewa, Nyanja',
  },
  {
    id: 'zh',
    label: 'Chinese',
  },
  {
    id: 'zh-cn',
    label: 'Chinese - China',
  },
  {
    id: 'zh-hk',
    label: 'Chinese - Hong Kong SAR',
  },
  {
    id: 'zh-mo',
    label: 'Chinese - Macau SAR',
  },
  {
    id: 'zh-sg',
    label: 'Chinese - Singapore',
  },
  {
    id: 'zh-tw',
    label: 'Chinese - Taiwan',
  },
  {
    id: 'cv',
    label: 'Chuvash',
  },
  {
    id: 'kw',
    label: 'Cornish',
  },
  {
    id: 'co',
    label: 'Corsican',
  },
  {
    id: 'cr',
    label: 'Cree',
  },
  {
    id: 'hr',
    label: 'Croatian',
  },
  {
    id: 'cs',
    label: 'Czech',
  },
  {
    id: 'da',
    label: 'Danish',
  },
  {
    id: 'dv',
    label: 'Divehi, Dhivehi, Maldivian',
  },
  {
    id: 'nl',
    label: 'Dutch',
  },
  {
    id: 'nl-be',
    label: 'Dutch - Belgium',
  },
  {
    id: 'nl-nl',
    label: 'Dutch - Netherlands',
  },
  {
    id: 'dz',
    label: 'Dzongkha',
  },
  {
    id: 'en',
    label: 'English',
  },
  {
    id: 'en-au',
    label: 'English - Australia',
  },
  {
    id: 'en-bz',
    label: 'English - Belize',
  },
  {
    id: 'en-ca',
    label: 'English - Canada',
  },
  {
    id: 'en-cb',
    label: 'English - Caribbean',
  },
  {
    id: 'en-gb',
    label: 'English - Great Britain',
  },
  {
    id: 'en-in',
    label: 'English - India',
  },
  {
    id: 'en-ie',
    label: 'English - Ireland',
  },
  {
    id: 'en-jm',
    label: 'English - Jamaica',
  },
  {
    id: 'en-nz',
    label: 'English - New Zealand',
  },
  {
    id: 'en-ph',
    label: 'English - Phillippines',
  },
  {
    id: 'en-za',
    label: 'English - Southern Africa',
  },
  {
    id: 'en-tt',
    label: 'English - Trinidad',
  },
  {
    id: 'en-us',
    label: 'English - United States',
  },
  {
    id: 'eo',
    label: 'Esperanto',
  },
  {
    id: 'et',
    label: 'Estonian',
  },
  {
    id: 'ee',
    label: 'Ewe',
  },
  {
    id: 'fo',
    label: 'Faroese',
  },
  {
    id: 'fj',
    label: 'Fijian',
  },
  {
    id: 'fi',
    label: 'Finnish',
  },
  {
    id: 'fr',
    label: 'French',
  },
  {
    id: 'fr-be',
    label: 'French - Belgium',
  },
  {
    id: 'fr-ca',
    label: 'French - Canada',
  },
  {
    id: 'fr-fr',
    label: 'French - France',
  },
  {
    id: 'fr-lu',
    label: 'French - Luxembourg',
  },
  {
    id: 'fr-ch',
    label: 'French - Switzerland',
  },
  {
    id: 'ff',
    label: 'Fula, Fulah, Pulaar, Pular',
  },
  {
    id: 'gl',
    label: 'Galician',
  },
  {
    id: 'gd-ie',
    label: 'Gaelic - Ireland',
  },
  {
    id: 'lg',
    label: 'Ganda',
  },
  {
    id: 'ka',
    label: 'Georgian',
  },
  {
    id: 'de',
    label: 'German',
  },
  {
    id: 'de-at',
    label: 'German - Austria',
  },
  {
    id: 'de-de',
    label: 'German - Germany',
  },
  {
    id: 'de-li',
    label: 'German - Liechtenstein',
  },
  {
    id: 'de-lu',
    label: 'German - Luxembourg',
  },
  {
    id: 'de-ch',
    label: 'German - Switzerland',
  },
  {
    id: 'el',
    label: 'Greek (modern)',
  },
  {
    id: 'gn',
    label: 'Guaraní',
  },
  {
    id: 'gu',
    label: 'Gujarati',
  },
  {
    id: 'ht',
    label: 'Haitian, Haitian Creole',
  },
  {
    id: 'ha',
    label: 'Hausa',
  },
  {
    id: 'he',
    label: 'Hebrew (modern)',
  },
  {
    id: 'hz',
    label: 'Herero',
  },
  {
    id: 'hi',
    label: 'Hindi',
  },
  {
    id: 'ho',
    label: 'Hiri Motu',
  },
  {
    id: 'hu',
    label: 'Hungarian',
  },
  {
    id: 'is',
    label: 'Icelandic',
  },
  {
    id: 'io',
    label: 'Ido',
  },
  {
    id: 'ig',
    label: 'Igbo',
  },
  {
    id: 'id',
    label: 'Indonesian',
  },
  {
    id: 'ia',
    label: 'Interlingua',
  },
  {
    id: 'ie',
    label: 'Interlingue',
  },
  {
    id: 'iu',
    label: 'Inuktitut',
  },
  {
    id: 'ik',
    label: 'Inupiaq',
  },
  {
    id: 'ga',
    label: 'Irish',
  },
  {
    id: 'it',
    label: 'Italian',
  },
  {
    id: 'it-it',
    label: 'Italian - Italy',
  },
  {
    id: 'it-ch',
    label: 'Italian - Switzerland',
  },
  {
    id: 'ja',
    label: 'Japanese',
  },
  {
    id: 'jv',
    label: 'Javanese',
  },
  {
    id: 'kl',
    label: 'Kalaallisut, Greenlandic',
  },
  {
    id: 'kn',
    label: 'Kannada',
  },
  {
    id: 'kr',
    label: 'Kanuri',
  },
  {
    id: 'ks',
    label: 'Kashmiri',
  },
  {
    id: 'kk',
    label: 'Kazakh',
  },
  {
    id: 'km',
    label: 'Khmer',
  },
  {
    id: 'ki',
    label: 'Kikuyu, Gikuyu',
  },
  {
    id: 'rw',
    label: 'Kinyarwanda',
  },
  {
    id: 'rn',
    label: 'Kirundi',
  },
  {
    id: 'kv',
    label: 'Komi',
  },
  {
    id: 'kg',
    label: 'Kongo',
  },
  {
    id: 'ko',
    label: 'Korean',
  },
  {
    id: 'ku',
    label: 'Kurdish',
  },
  {
    id: 'kj',
    label: 'Kwanyama, Kuanyama',
  },
  {
    id: 'ky',
    label: 'Kyrgyz',
  },
  {
    id: 'lo',
    label: 'Lao',
  },
  {
    id: 'la',
    label: 'Latin',
  },
  {
    id: 'lv',
    label: 'Latvian',
  },
  {
    id: 'li',
    label: 'Limburgish, Limburgan, Limburger',
  },
  {
    id: 'ln',
    label: 'Lingala',
  },
  {
    id: 'lt',
    label: 'Lithuanian',
  },
  {
    id: 'lu',
    label: 'Luba-Katanga',
  },
  {
    id: 'lb',
    label: 'Luxembourgish, Letzeburgesch',
  },
  {
    id: 'mk',
    label: 'Macedonian',
  },
  {
    id: 'mg',
    label: 'Malagasy',
  },
  {
    id: 'ms',
    label: 'Malay',
  },
  {
    id: 'ms-bn',
    label: 'Malay - Brunei',
  },
  {
    id: 'ms-my',
    label: 'Malay - Malaysia',
  },
  {
    id: 'ml',
    label: 'Malayalam',
  },
  {
    id: 'mt',
    label: 'Maltese',
  },
  {
    id: 'gv',
    label: 'Manx',
  },
  {
    id: 'mr',
    label: 'Marathi (Marāṭhī)',
  },
  {
    id: 'mh',
    label: 'Marshallese',
  },
  {
    id: 'mn',
    label: 'Mongolian',
  },
  {
    id: 'me',
    label: 'Montenegrin',
  },
  {
    id: 'mi',
    label: 'Māori',
  },
  {
    id: 'na',
    label: 'Nauruan',
  },
  {
    id: 'nv',
    label: 'Navajo, Navaho',
  },
  {
    id: 'ng',
    label: 'Ndonga',
  },
  {
    id: 'ne',
    label: 'Nepali',
  },
  {
    id: 'nd',
    label: 'Northern Ndebele',
  },
  {
    id: 'se',
    label: 'Northern Sami',
  },
  {
    id: 'no',
    label: 'Norwegian',
  },
  {
    id: 'no-no',
    label: 'Norwegian - Bokml',
  },
  {
    id: 'no-no',
    label: 'Norwegian - Nynorsk',
  },
  {
    id: 'nb',
    label: 'Norwegian Bokmål',
  },
  {
    id: 'nn',
    label: 'Norwegian Nynorsk',
  },
  {
    id: 'ii',
    label: 'Nuosu',
  },
  {
    id: 'oc',
    label: 'Occitan',
  },
  {
    id: 'oj',
    label: 'Ojibwe, Ojibwa',
  },
  {
    id: 'cu',
    label: 'Old Church Slavonic, Church Slavonic,Old Bulgarian',
  },
  {
    id: 'or',
    label: 'Oriya',
  },
  {
    id: 'om',
    label: 'Oromo',
  },
  {
    id: 'os',
    label: 'Ossetian, Ossetic',
  },
  {
    id: 'pa',
    label: 'Panjabi, Punjabi',
  },
  {
    id: 'ps',
    label: 'Pashto, Pushto',
  },
  {
    id: 'fa',
    label: 'Persian (Farsi)',
  },
  {
    id: 'pl',
    label: 'Polish',
  },
  {
    id: 'pt',
    label: 'Portuguese',
  },
  {
    id: 'pt-br',
    label: 'Portuguese - Brazil',
  },
  {
    id: 'pt-pt',
    label: 'Portuguese - Portugal',
  },
  {
    id: 'pi',
    label: 'Pāli',
  },
  {
    id: 'qu',
    label: 'Quechua',
  },
  {
    id: 'ro',
    label: 'Romanian',
  },
  {
    id: 'ro-mo',
    label: 'Romanian - Moldova',
  },
  {
    id: 'rm',
    label: 'Romansh',
  },
  {
    id: 'ru',
    label: 'Russian',
  },
  {
    id: 'ru-mo',
    label: 'Russian - Moldova',
  },
  {
    id: 'sm',
    label: 'Samoan',
  },
  {
    id: 'sg',
    label: 'Sango',
  },
  {
    id: 'sa',
    label: 'Sanskrit (Saṁskṛta)',
  },
  {
    id: 'sc',
    label: 'Sardinian',
  },
  {
    id: 'gd',
    label: 'Scottish Gaelic, Gaelic',
  },
  {
    id: 'sr',
    label: 'Serbian',
  },
  {
    id: 'sr-sp',
    label: 'Serbian - Cyrillic',
  },
  {
    id: 'sr-sp',
    label: 'Serbian - Latin',
  },
  {
    id: 'sn',
    label: 'Shona',
  },
  {
    id: 'sd',
    label: 'Sindhi',
  },
  {
    id: 'si',
    label: 'Sinhala, Sinhalese',
  },
  {
    id: 'sk',
    label: 'Slovak',
  },
  {
    id: 'sl',
    label: 'Slovene',
  },
  {
    id: 'so',
    label: 'Somali',
  },
  {
    id: 'nr',
    label: 'Southern Ndebele',
  },
  {
    id: 'st',
    label: 'Southern Sotho',
  },
  {
    id: 'es',
    label: 'Spanish',
  },
  {
    id: 'es-ar',
    label: 'Spanish - Argentina',
  },
  {
    id: 'es-bo',
    label: 'Spanish - Bolivia',
  },
  {
    id: 'es-cl',
    label: 'Spanish - Chile',
  },
  {
    id: 'es-co',
    label: 'Spanish - Colombia',
  },
  {
    id: 'es-cr',
    label: 'Spanish - Costa Rica',
  },
  {
    id: 'es-do',
    label: 'Spanish - Dominican Republic',
  },
  {
    id: 'es-ec',
    label: 'Spanish - Ecuador',
  },
  {
    id: 'es-sv',
    label: 'Spanish - El Salvador',
  },
  {
    id: 'es-gt',
    label: 'Spanish - Guatemala',
  },
  {
    id: 'es-hn',
    label: 'Spanish - Honduras',
  },
  {
    id: 'es-mx',
    label: 'Spanish - Mexico',
  },
  {
    id: 'es-ni',
    label: 'Spanish - Nicaragua',
  },
  {
    id: 'es-pa',
    label: 'Spanish - Panama',
  },
  {
    id: 'es-py',
    label: 'Spanish - Paraguay',
  },
  {
    id: 'es-pe',
    label: 'Spanish - Peru',
  },
  {
    id: 'es-pr',
    label: 'Spanish - Puerto Rico',
  },
  {
    id: 'es-es',
    label: 'Spanish - Spain (Traditional)',
  },
  {
    id: 'es-uy',
    label: 'Spanish - Uruguay',
  },
  {
    id: 'es-ve',
    label: 'Spanish - Venezuela',
  },
  {
    id: 'su',
    label: 'Sundanese',
  },
  {
    id: 'sw',
    label: 'Swahili',
  },
  {
    id: 'ss',
    label: 'Swati',
  },
  {
    id: 'sv',
    label: 'Swedish',
  },
  {
    id: 'sv-fi',
    label: 'Swedish - Finland',
  },
  {
    id: 'sv-se',
    label: 'Swedish - Sweden',
  },
  {
    id: 'tl',
    label: 'Tagalog',
  },
  {
    id: 'ty',
    label: 'Tahitian',
  },
  {
    id: 'tg',
    label: 'Tajik',
  },
  {
    id: 'ta',
    label: 'Tamil',
  },
  {
    id: 'tt',
    label: 'Tatar',
  },
  {
    id: 'te',
    label: 'Telugu',
  },
  {
    id: 'th',
    label: 'Thai',
  },
  {
    id: 'bo',
    label: 'Tibetan Standard, Tibetan, Central',
  },
  {
    id: 'ti',
    label: 'Tigrinya',
  },
  {
    id: 'to',
    label: 'Tonga (Tonga Islands)',
  },
  {
    id: 'ts',
    label: 'Tsonga',
  },
  {
    id: 'tn',
    label: 'Tswana',
  },
  {
    id: 'tr',
    label: 'Turkish',
  },
  {
    id: 'tk',
    label: 'Turkmen',
  },
  {
    id: 'tw',
    label: 'Twi',
  },
  {
    id: 'uk',
    label: 'Ukrainian',
  },
  {
    id: 'ur',
    label: 'Urdu',
  },
  {
    id: 'ug',
    label: 'Uyghur',
  },
  {
    id: 'uz',
    label: 'Uzbek',
  },
  {
    id: 'uz-uz',
    label: 'Uzbek - Cyrillic',
  },
  {
    id: 'uz-uz',
    label: 'Uzbek - Latin',
  },
  {
    id: 've',
    label: 'Venda',
  },
  {
    id: 'vi',
    label: 'Vietnamese',
  },
  {
    id: 'vo',
    label: 'Volapük',
  },
  {
    id: 'wa',
    label: 'Walloon',
  },
  {
    id: 'cy',
    label: 'Welsh',
  },
  {
    id: 'fy',
    label: 'Western Frisian',
  },
  {
    id: 'wo',
    label: 'Wolof',
  },
  {
    id: 'xh',
    label: 'Xhosa',
  },
  {
    id: 'yi',
    label: 'Yiddish',
  },
  {
    id: 'yo',
    label: 'Yoruba',
  },
  {
    id: 'za',
    label: 'Zhuang, Chuang',
  },
  {
    id: 'zu',
    label: 'Zulu',
  },
];
