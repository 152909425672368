/* global MOBILE_BUILDER_URL */

import React, { useState, useMemo, useLayoutEffect, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { FloatingPanel, Input, Button, Icon } from '@appcues/sonar';
import { faMagnifyingGlass } from '@fortawesome/pro-regular-svg-icons/faMagnifyingGlass';
import { faBoxArchive } from '@fortawesome/pro-solid-svg-icons/faBoxArchive';
import { faCameraViewfinder } from '@fortawesome/pro-regular-svg-icons/faCameraViewfinder';
import useToggle from 'ext/lib/hooks/use-toggle';
import Portal from 'ext/components/Portal';
import { Tooltip } from 'ext/components/ui';
import { useFloatingWindow } from '@appcues/shared/hooks';
import { selectExperience } from 'entities/experiences';
import { summaryFocus, selectSDKToken } from 'entities/screens';
import { isPreviewBuild } from 'lib/location';
import PreviewQRCode from 'components/PreviewQrCode';
import ScreenList from 'components/ScreensList';
import { TYPES } from 'components/PreviewProxy';
import { Container, FilterWrapper } from './styled';

const SIDEBAR_TOP_BAR = 56;
const FLOATING_PANEL_WIDTH = 300;

const STEPS = {
  SCREEN_LIST: 'SCREEN_LIST',
  QR_CODE: 'QR_CODE',
};

const FloatingScreenCapture = ({
  appId,
  sdkToken,
  refToAttach,
  onDismiss,
  onScreenStep,
}) => {
  const [initialPosition, setInitialPosition] = useState(null);
  const [step, setStep] = useState(STEPS.SCREEN_LIST);
  const [searchFilter, setSearchFilter] = useState();
  const [showArchived, setShowArchived] = useToggle();

  const previewUrl = useMemo(() => {
    const { pathname } = window.location;
    const hash = isPreviewBuild(pathname) ? `${pathname}#` : '';
    return `${MOBILE_BUILDER_URL}${hash}?type=${TYPES.CAPTURE_SCREEN}&appId=${appId}&sdkToken=${sdkToken}`;
  }, [appId, sdkToken]);

  const { dragHandlers, containerRef, containerStyle, pressed } =
    useFloatingWindow({
      strategy: 'fixed',
      initialPosition,
    });

  useLayoutEffect(() => {
    if (!refToAttach.current) return;

    const { left } = refToAttach.current.getBoundingClientRect();
    setInitialPosition({ x: left - FLOATING_PANEL_WIDTH, y: SIDEBAR_TOP_BAR });
  }, [refToAttach]);

  useEffect(() => {
    if (step === STEPS.SCREEN_LIST) {
      onScreenStep();
    }
  }, [onScreenStep, step]);

  const onBack = () => {
    setStep(STEPS.SCREEN_LIST);
  };

  return (
    <Portal>
      <FloatingPanel.Root ref={containerRef} style={containerStyle}>
        <FloatingPanel.Header
          title="Select screen"
          onDismiss={onDismiss}
          onBack={onBack}
          showBack={step === STEPS.QR_CODE}
          dragHandlers={{
            ...dragHandlers,
            dragging: pressed,
          }}
        />
        <Container>
          <FloatingPanel.Body>
            {step === STEPS.SCREEN_LIST && (
              <>
                <FilterWrapper>
                  <Input
                    icon={faMagnifyingGlass}
                    placeholder="Search captured screens..."
                    onChange={({ target }) => setSearchFilter(target.value)}
                  />
                  <Tooltip
                    label={`${showArchived ? 'Hide' : 'Show'} archived`}
                    placement="bottom"
                  >
                    <Button
                      variant="secondary"
                      onClick={() => setShowArchived()}
                      aria-pressed={showArchived}
                    >
                      <Icon icon={faBoxArchive} aria-label="Archived" />
                    </Button>
                  </Tooltip>
                </FilterWrapper>

                <ScreenList
                  searchFilter={searchFilter}
                  showArchived={showArchived}
                />
                <Button
                  variant="secondary"
                  onClick={() => setStep(STEPS.QR_CODE)}
                  fluid
                >
                  <Icon icon={faCameraViewfinder} />
                  Capture screen
                </Button>
              </>
            )}
            {step === STEPS.QR_CODE && (
              <PreviewQRCode previewUrl={previewUrl} onClick={onBack} />
            )}
          </FloatingPanel.Body>
        </Container>
      </FloatingPanel.Root>
    </Portal>
  );
};

FloatingScreenCapture.propTypes = {
  appId: PropTypes.string,
  sdkToken: PropTypes.string,
  refToAttach: PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  onDismiss: PropTypes.func,
  onScreenStep: PropTypes.func,
};

const mapStateToProps = state => {
  const { appId } = selectExperience(state);
  const sdkToken = selectSDKToken(state);

  return {
    appId,
    sdkToken,
  };
};

const mapDispatchToProps = {
  onScreenStep: summaryFocus,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FloatingScreenCapture);
