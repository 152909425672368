import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FieldSet, DebouncedInput as Input, Switch } from 'ext/components/ui';
import { getTrackEvent } from 'lib/actions';

export default function TrackEventOption({ config, onChange }) {
  const [trackEvent, setTrackEvent] = useState(!!config?.eventName);
  const [eventName, setEventName] = useState('');

  if (config?.eventName && config?.eventName !== eventName) {
    setEventName(config?.eventName);
    setTrackEvent(true);
  }

  if (!config?.eventName && eventName !== '') {
    setEventName('');
    setTrackEvent(false);
  }

  const handleTrackEvent = () => {
    // If there was an event before we delete it
    if (eventName) {
      onChange({ events: [] });
    }

    setEventName('');
    setTrackEvent(!trackEvent);
  };

  const handleEventName = event => {
    const { value } = event.target;
    setEventName(value);
    onChange({ events: [getTrackEvent(value)] });
  };

  return (
    <>
      <FieldSet>
        <Switch
          label="Track event"
          checked={trackEvent}
          onChange={handleTrackEvent}
        />
      </FieldSet>

      {trackEvent && (
        <FieldSet>
          <Input
            id="track-event-name"
            defaultValue={eventName}
            onChange={handleEventName}
            placeholder="Enter an event name to track"
          />
        </FieldSet>
      )}
    </>
  );
}

TrackEventOption.propTypes = {
  config: PropTypes.shape({
    eventName: PropTypes.string,
  }),
  onChange: PropTypes.func,
};
