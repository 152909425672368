import isEmpty from 'lodash.isempty';
import { get, set, push } from 'object-path-immutable';
import { STANDARD, SLIDEOUT } from 'lib/presentation';
import {
  BACKDROP,
  BACKGROUND_CONTENT,
  CAROUSEL,
  MODAL,
  EFFECTS,
  EMBED,
  PAGING_DOTS,
  SKIPPABLE,
  SKIPPABLE_OPTIONS,
  STEP_TRANSITION_ANIMATION,
  SCREEN,
  TOOLTIP,
  TRAITS,
} from 'lib/trait';

/**
 * Transform entity into UI consumable configuration to be used on Redux
 *
 * The method gets the entity and a mapping object.
 * Inside the mapping object there are functions `from` and `to`
 * `from` is used to `transform` the entity into the config (editor)
 *
 * If the `from` function returns undefined or empty object,
 * the key will not be added to the config.
 *
 * @param {object} entity - Entity object e.g. step
 * @param {object} mapping - Mapping functions
 * @return {object} Entity mapped config
 */
export function transform(entity, mapping) {
  return Object.entries(mapping).reduce((acc, [key, { from }]) => {
    const value = from?.(entity, acc);

    return {
      ...acc,
      ...(value !== undefined &&
        (typeof value !== 'object' || !isEmpty(value)) && {
          [key]: value,
        }),
    };
  }, {});
}

/**
 * Revert the config (editor) back to the entity to save on the SDK
 *
 * The method gets the entity and a mapping object.
 * Inside the mapping object there are functions `from` and `to`
 * `to` is used to `revert` the config (editor UI) into the entity (SDK)
 *
 * @param {object} config - Entity mapped config
 * @param {object} mapping - Mapping functions
 * @return {object} Updated partial entity object e.g. step
 */
export function revert(config, mapping) {
  return Object.values(mapping).reduce(
    (acc, { to }) => to?.(acc, config) ?? acc,
    { traits: [] }
  );
}

export function merge(current, updated) {
  const handled = new Set(TRAITS);
  const unhandled = current.traits.filter(({ type }) => !handled.has(type));
  return { ...current, traits: [...updated.traits, ...unhandled] };
}

/**
 * Find index of trait for getter path
 *
 * @param {object[]} traits - List of traits
 * @param {string} name - Type of trait
 * @return {number} Index of trait in the list
 */
function findIndex(traits = [], name) {
  return traits.findIndex(({ type }) => type === name);
}

/**
 * Find index of layout trait (modal, tooltip or embed)
 *
 * @param {object[]} traits - List of traits
 * @return {number} index of trait in the list
 */
function findLayoutTrait(traits = []) {
  const modalIndex = findIndex(traits, MODAL);
  const tooltipIndex = findIndex(traits, TOOLTIP);
  const embedIndex = findIndex(traits, EMBED);

  if (embedIndex >= 0) {
    return embedIndex;
  }

  return modalIndex >= 0 ? modalIndex : tooltipIndex;
}

/**
 * Step group mapping
 * @constant
 * @type {object}
 *
 */
export const stepGroupMapping = {
  setup: {
    to: (_, editor) => {
      return { traits: [{ type: editor.type }] };
    },
  },

  type: {
    from: ({ traits }) => {
      const index = findLayoutTrait(traits);
      return index < 0 ? undefined : traits[index]?.type;
    },
  },

  presentation: {
    from: ({ traits }) => {
      const index = findLayoutTrait(traits);
      return get(traits, [index, 'config', 'slideout'])
        ? SLIDEOUT
        : get(traits, [index, 'config', 'presentationStyle']);
    },
    to: (current, { presentation }) => {
      const index = findLayoutTrait(current.traits);
      const presentationStyle =
        presentation === SLIDEOUT ? STANDARD : presentation;
      const path = ['traits', index, 'config', 'presentationStyle'];
      return index < 0 ? current : set(current, path, presentationStyle);
    },
  },

  style: {
    from: ({ traits }) => {
      const index = findLayoutTrait(traits);
      return get(traits, [index, 'config', 'style'], {});
    },
    to: (current, { style = {} }) => {
      const index = findLayoutTrait(current.traits);
      const path = ['traits', index, 'config', 'style'];
      return index < 0 ? current : set(current, path, style);
    },
  },

  slideout: {
    from: ({ traits }) => {
      const index = findLayoutTrait(traits);
      return get(traits, [index, 'config', 'slideout']);
    },
    to: (current, { slideout }) => {
      const index = findLayoutTrait(current.traits);
      const path = ['traits', index, 'config', 'slideout'];
      return index < 0 ? current : set(current, path, slideout);
    },
  },

  transition: {
    from: ({ traits }) => {
      const index = findLayoutTrait(traits);
      return get(traits, [index, 'config', 'transition']);
    },
    to: (current, { transition }) => {
      const index = findLayoutTrait(current.traits);
      const path = ['traits', index, 'config', 'transition'];
      return index < 0 ? current : set(current, path, transition);
    },
  },

  skippable: {
    from: ({ traits }) => {
      const index = findIndex(traits, SKIPPABLE);
      return index > -1
        ? get(traits, [index, 'config'], {
            buttonAppearance: SKIPPABLE_OPTIONS.DEFAULT,
          })
        : false;
    },
    to: (current, { skippable }) => {
      const trait = { type: SKIPPABLE, config: skippable };
      return skippable ? push(current, ['traits'], trait) : current;
    },
  },

  carousel: {
    from: ({ traits }) => {
      return findIndex(traits, CAROUSEL) >= 0;
    },
    to: (current, { carousel }) => {
      const trait = { type: CAROUSEL };
      return carousel ? push(current, ['traits'], trait) : current;
    },
  },

  pagingDots: {
    from: ({ traits }) => {
      const index = findIndex(traits, PAGING_DOTS);
      return get(traits, [index, 'config']);
    },
    to: (current, { pagingDots }) => {
      const trait = { type: PAGING_DOTS, config: pagingDots };
      return pagingDots ? push(current, ['traits'], trait) : current;
    },
  },

  backgroundContent: {
    from: ({ traits }) => {
      const index = findIndex(traits, BACKGROUND_CONTENT);
      return get(traits, [index, 'config', 'content']);
    },
    to: (current, { backgroundContent }) => {
      const trait = {
        type: BACKGROUND_CONTENT,
        config: {
          content: backgroundContent,
        },
      };
      return backgroundContent ? push(current, ['traits'], trait) : current;
    },
  },

  pointer: {
    from: ({ traits }) => {
      const index = findLayoutTrait(traits);

      const {
        hidePointer: hidden,
        pointerBase: width,
        pointerLength: height,
        pointerCornerRadius: cornerRadius,
      } = get(traits, [index, 'config'], {});

      return {
        ...(hidden && { hidden }),
        ...(width && { width }),
        ...(height && { height }),
        ...(cornerRadius && { cornerRadius }),
      };
    },
    to: (current, { pointer = {} }) => {
      const index = findLayoutTrait(current.traits);
      const tooltipTrait = get(current.traits, [index, 'config'], {});

      const {
        hidden: hidePointer,
        width: pointerBase,
        height: pointerLength,
        cornerRadius: pointerCornerRadius,
      } = pointer;

      const trait = {
        type: TOOLTIP,
        config: {
          ...tooltipTrait,
          hidePointer,
          pointerBase,
          pointerLength,
          pointerCornerRadius,
        },
      };

      return Object.keys(pointer).length > 0
        ? set(current, ['traits', index], trait)
        : current;
    },
  },

  backdrop: {
    from: ({ traits }) => {
      const index = findIndex(traits, BACKDROP);
      return get(traits, [index, 'config']);
    },
    to: (current, { backdrop }) => {
      const trait = { type: BACKDROP, config: backdrop };
      return backdrop ? push(current, ['traits'], trait) : current;
    },
  },

  effects: {
    from: ({ traits }) => {
      const index = findIndex(traits, EFFECTS);
      return get(traits, [index, 'config']);
    },
    to: (current, { effects }) => {
      const trait = { type: EFFECTS, config: effects };
      return effects ? push(current, ['traits'], trait) : current;
    },
  },

  animation: {
    from: ({ traits }) => {
      return findIndex(traits, STEP_TRANSITION_ANIMATION) >= 0;
    },
    to: (current, { animation }) => {
      const trait = { type: STEP_TRANSITION_ANIMATION };
      return animation ? push(current, ['traits'], trait) : current;
    },
  },

  screen: {
    from: ({ traits }) => {
      const index = findIndex(traits, SCREEN);
      return get(traits, [index, 'config']);
    },
    to: (current, { screen }) => {
      const trait = { type: SCREEN, config: screen };
      return screen ? push(current, ['traits'], trait) : current;
    },
  },

  frameID: {
    from: ({ traits }) => {
      const index = findLayoutTrait(traits);
      return get(traits, [index, 'config', 'frameID']);
    },
    to: (current, { frameID }) => {
      const index = findLayoutTrait(current.traits);
      const path = ['traits', index, 'config', 'frameID'];
      return index < 0 ? current : set(current, path, frameID);
    },
  },
};
