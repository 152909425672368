import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Tether from 'ext/components/Tether';
import { Button, FontIcon } from 'ext/components/ui';
import useClickOutside from 'ext/lib/hooks/use-click-outside';
import useAnalytics from 'ext/lib/hooks/use-analytics';
import { isEmbedExperience } from 'lib/experiences';
import { selectExperience } from 'entities/experiences';
import { add as addStepGroup } from 'entities/step-groups';
import {
  add as addStepChild,
  selectStepChild,
  ContentShape,
} from 'entities/step-children';
import { selectSelected } from 'entities/selected';
import { LAYOUT_TRAITS, TRAIT_LABELS } from 'lib/trait';
import { PRESENTATIONS, PRESENTATIONS_TYPES } from 'lib/presentation';
import { PLATFORMS } from 'lib/platform';
import { getLocaleVariations } from 'lib/localization';
import AddMenu from './AddMenu';
import { AddWrapper } from './styled';

export function AddManager({
  selectedTrait,
  selectedPresentation,
  isMenuOpen,
  toggleIsMenuOpen,
  experienceType,
  platform,
  content,
  onAddStep,
  onAddGroup,
}) {
  const $button = useRef();
  const $menu = useRef();

  useClickOutside([$button, $menu], isMenuOpen && toggleIsMenuOpen);

  const { track } = useAnalytics();

  const onTrack = ({ layoutTrait, presentation, stepType }) => {
    const traitType = TRAIT_LABELS[layoutTrait];
    const presentationType = PRESENTATIONS[layoutTrait][presentation];

    track('Mobile Builder interaction', {
      name: `Added ${presentationType} to ${traitType} ${stepType} Step`,
      component: 'AddManager',
    });
  };

  const locales = getLocaleVariations(content);

  const handleAddGroup = ({ layoutTrait, presentation }) => {
    onTrack({ layoutTrait, presentation, stepType: 'group' });
    onAddGroup({ layoutTrait, presentation, locales });
  };

  const handleAddStep = () => {
    onTrack({
      layoutTrait: selectedTrait,
      presentation: selectedPresentation,
      stepType: 'child',
    });
    onAddStep({ presentation: selectedPresentation, locales });
  };

  return (
    <AddWrapper>
      <Tether
        attachment={
          <AddMenu
            ref={$menu}
            platform={platform}
            selectedPresentation={selectedPresentation}
            selectedTrait={selectedTrait}
            onClick={toggleIsMenuOpen}
            handleAddGroup={handleAddGroup}
            handleAddStep={handleAddStep}
          />
        }
        visible={isMenuOpen}
        placement="top"
        offset={{ y: 15 }}
      >
        <Button
          ref={$button}
          kind="secondary"
          onClick={
            isEmbedExperience(experienceType) ? handleAddStep : toggleIsMenuOpen
          }
        >
          <FontIcon icon="plus" />
          Add
        </Button>
      </Tether>
    </AddWrapper>
  );
}

AddManager.propTypes = {
  experienceType: PropTypes.string,
  platform: PropTypes.oneOf(PLATFORMS),
  content: ContentShape,
  selectedTrait: PropTypes.oneOf(LAYOUT_TRAITS),
  onAddGroup: PropTypes.func,
  onAddStep: PropTypes.func,
  selectedPresentation: PropTypes.oneOf(PRESENTATIONS_TYPES),
  isMenuOpen: PropTypes.bool,
  toggleIsMenuOpen: PropTypes.func,
};

const mapStateToProps = state => {
  const { type, platform } = selectExperience(state) ?? {};
  const { stepChild: stepId } = selectSelected(state) ?? {};
  const { content } = selectStepChild(state, stepId) ?? {};
  return {
    experienceType: type,
    platform,
    content,
  };
};

const mapDispatchToProps = {
  onAddGroup: addStepGroup,
  onAddStep: addStepChild,
};

export default connect(mapStateToProps, mapDispatchToProps)(AddManager);
