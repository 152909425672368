import { EMBED, CAROUSEL, SKIPPABLE_OPTIONS } from 'lib/trait';
import { ANNOUNCEMENT, SURVEY, CTA } from 'lib/presentation';
import { skippableTemplate } from './skippable';
import { pagingDotsTemplate } from './paging-dots';

export const embedTemplate = presentation => {
  const announcementStyles = {
    backgroundColor: {
      light: '#FFF0B3',
    },
    paddingBottom: 0,
  };

  const ctaStyles = {
    backgroundColor: {
      light: '#165196',
    },
    cornerRadius: 12,
    marginBottom: 0,
    marginLeading: 12,
    marginTop: 0,
    marginTrailing: 12,
    paddingBottom: 18,
    paddingTop: 10,
  };

  const style = {
    backgroundColor: { light: '#FFFFFF' },
    paddingBottom: 16,
    ...(presentation === ANNOUNCEMENT && announcementStyles),
    ...(presentation === CTA && ctaStyles),
  };

  return [
    {
      type: EMBED,
      config: {
        frameID: '',
        transition: 'fade',
        presentationStyle: presentation,
        style,
      },
    },
    skippableTemplate(SKIPPABLE_OPTIONS.MINIMAL, presentation),
    ...(presentation !== SURVEY
      ? [{ type: CAROUSEL }, pagingDotsTemplate]
      : []),
  ];
};
