import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useToggle from 'ext/lib/hooks/use-toggle';
import { selectAccountId } from 'ext/entities/account';
import {
  selectUserAccounts,
  selectHasMultipleAccounts,
} from 'ext/entities/accounts';
import { logout } from 'lib/auth';
import { selectSpoofing } from 'entities/auth';
import { selectUser, Shape as UserShape } from 'entities/user';
import BottomMenuOptions from './BottomMenuOptions';
import AccountsModal from './AccountsModal';
import SpoofingModal from './SpoofingModal';
import { menuOptionsHandler } from './menu-options-handler';

export function BottomMenuManager({
  accountId,
  accounts,
  hasMultipleAccounts,
  isSpoofing,
  user,
}) {
  const { isAdmin } = user ?? {};

  const [accountsVisible, toggleAccounts] = useToggle();
  const [spoofingVisible, toggleSpoofing] = useToggle();

  const account = {
    accountId,
    isAdmin,
    hasMultipleAccounts,
    toggleAccounts,
  };

  const spoofing = {
    isSpoofing,
    toggleSpoofing,
  };

  const options = menuOptionsHandler({ account, spoofing, logout });

  return (
    <>
      <BottomMenuOptions options={options} />

      <AccountsModal
        accounts={accounts}
        onClose={toggleAccounts}
        selected={accountId}
        visible={accountsVisible}
      />

      <SpoofingModal onClose={toggleSpoofing} visible={spoofingVisible} />
    </>
  );
}

BottomMenuManager.propTypes = {
  accountId: PropTypes.string,
  accounts: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  hasMultipleAccounts: PropTypes.bool,
  isSpoofing: PropTypes.bool,
  user: UserShape,
};

const mapStateToProps = state => ({
  accountId: selectAccountId(state),
  accounts: selectUserAccounts(state),
  hasMultipleAccounts: selectHasMultipleAccounts(state),
  isSpoofing: selectSpoofing(state),
  user: selectUser(state),
});

export default connect(mapStateToProps)(BottomMenuManager);
