import React, { Fragment, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { TileButton, Button } from '@appcues/sonar';
import Portal from 'ext/components/Portal';
import useAnalytics from 'ext/lib/hooks/use-analytics';
import { add, generatePresentationStep } from 'entities/step-groups';
import { update } from 'entities/experiences';
import { isEmbedExperience, EXPERIENCE_TYPES } from 'lib/experiences';
import { MODAL, TOOLTIP, EMBED, TRAIT_LABELS } from 'lib/trait';
import { PLATFORMS, IONIC } from 'lib/platform';
import {
  PRESENTATIONS,
  EMPTY,
  getAllowedPresentations,
} from 'lib/presentation';
import { Embed, Device } from 'components/Editor';
import PresentationIcon from './PresentationIcons';
import {
  Wrapper,
  Text,
  PresentationGroups,
  ToggleGroupWrapper,
  TileGroup,
  ActionsGroup,
  PreviewPane,
} from './styled';

export function Presentations({ experience, onClick, onUpdate }) {
  const { track } = useAnalytics();

  const [traitPresentation, setTraitPresentation] = useState();
  const [presentationStep, setPresentationStep] = useState({});
  const { content, editor, traits } = presentationStep;

  const { id, type, platform } = experience;
  const isEmbed = isEmbedExperience(type);
  const isIonic = platform === IONIC;
  const switchTypeLabel = isEmbed ? 'Modals & Tooltips' : 'Embeds';
  const layoutTraits = isEmbed ? [EMBED] : [MODAL, TOOLTIP];
  const PreviewViewport = isEmbed ? Embed : Device;

  const handleClick = (layoutTrait, presentation) => () => {
    const name = isEmbed
      ? 'Created Embeds from Scratch'
      : `Created ${PRESENTATIONS[layoutTrait][presentation]} ${TRAIT_LABELS[layoutTrait]}`;
    track('Mobile Builder interaction', {
      name,
      component: 'Presentations',
    });

    onClick({ layoutTrait, presentation });
  };

  const handleSwitchTypeClick = () => {
    const [DEFAULT_TYPE, EMBED_TYPE] = EXPERIENCE_TYPES;
    const updatedType = isEmbed ? DEFAULT_TYPE : EMBED_TYPE;
    onUpdate({ id, type: updatedType });
  };

  useEffect(() => {
    if (traitPresentation) {
      setPresentationStep(generatePresentationStep(traitPresentation));
    } else {
      setPresentationStep({});
    }
  }, [traitPresentation]);

  return (
    <Wrapper>
      <PresentationGroups>
        {layoutTraits.map(layoutTrait => (
          <Fragment key={layoutTrait}>
            <Text>{TRAIT_LABELS[layoutTrait].toUpperCase()}</Text>
            <ToggleGroupWrapper>
              <TileGroup type="single">
                {getAllowedPresentations(layoutTrait, platform).map(
                  ([presentation, label]) => (
                    <TileButton
                      key={presentation}
                      label={label}
                      onClick={handleClick(layoutTrait, presentation)}
                      onMouseEnter={() =>
                        setTraitPresentation({ layoutTrait, presentation })
                      }
                      onMouseLeave={() => setTraitPresentation()}
                    >
                      <PresentationIcon
                        layoutTrait={layoutTrait}
                        presentation={presentation}
                      />
                    </TileButton>
                  )
                )}
              </TileGroup>
            </ToggleGroupWrapper>
          </Fragment>
        ))}
      </PresentationGroups>

      {!isIonic && (
        <ActionsGroup>
          {isEmbed && (
            <Button variant="secondary" onClick={handleClick(EMBED, EMPTY)}>
              Start from scratch
            </Button>
          )}
          <Button variant="tertiary" onClick={handleSwitchTypeClick}>
            Switch to {switchTypeLabel}
          </Button>
        </ActionsGroup>
      )}

      <Portal>
        <PreviewPane visible={traitPresentation != null}>
          <PreviewViewport content={content} editor={editor} traits={traits} />
        </PreviewPane>
      </Portal>
    </Wrapper>
  );
}

Presentations.propTypes = {
  experience: PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.oneOf(EXPERIENCE_TYPES),
    platform: PropTypes.oneOf(PLATFORMS),
  }),
  onClick: PropTypes.func,
  onUpdate: PropTypes.func,
};

const mapDispatchToProps = {
  onClick: add,
  onUpdate: update,
};

export default connect(null, mapDispatchToProps)(Presentations);
