import { useState, useEffect } from 'react';
import debounce from 'lodash.debounce';
import {
  useFloating,
  flip,
  shift,
  offset as offsetMiddleware,
  Strategy,
  UseFloatingReturn,
  autoPlacement,
  AutoPlacementOptions,
} from '@floating-ui/react';
import animationThrottle from 'lib/animation-throttle';

type InitialPosition = {
  x?: number;
  y?: number;
};

export type UseFloatingWindowOptions = {
  initialPosition?: InitialPosition;
  offset?: number;
  strategy?: Strategy;
  parentEl?: HTMLElement;
  placement?: AutoPlacementOptions;
};

// as the positions of the floating-ui container is defined based on middlewares,
// a custom middleware is necessary to handle the initial position if desired.
function syncInitialPosition(pos: InitialPosition = {}) {
  return {
    name: 'syncInitialPosition',
    fn({ x, y, ...rest }: { x: number; y: number }) {
      return {
        x: pos?.x ?? x,
        y: pos?.y ?? y,
        ...rest,
      };
    },
  };
}

export const reorganizeWindow = (
  floating: UseFloatingReturn,
  parentEl: HTMLElement | null = null
) => {
  const container = floating.refs.floating.current as HTMLElement;
  const containerRect = container.getBoundingClientRect();
  let screenWidth = window.innerWidth;
  let screenHeight = window.innerHeight;

  if (parentEl) {
    const { height, width } = parentEl.getBoundingClientRect();
    screenHeight = height;
    screenWidth = width;
  }

  if (containerRect.left + containerRect.width > screenWidth) {
    // eslint-disable-next-line no-param-reassign
    floating.x = screenWidth - containerRect.width - 16;
    container.style.left = `${floating.x}px`;
  }

  if (containerRect.top + containerRect.height > screenHeight) {
    // eslint-disable-next-line no-param-reassign
    floating.y = screenHeight - containerRect.height - 16;
    container.style.top = `${floating.y}px`;
  }
};

const useFloatingWindow = ({
  initialPosition,
  offset = 0,
  strategy = 'absolute',
  parentEl,
  placement,
}: UseFloatingWindowOptions = {}) => {
  const [pressed, setPressed] = useState(false);
  const middleware = [shift(), offsetMiddleware(offset)];
  if (initialPosition) {
    middleware.push(syncInitialPosition(initialPosition));
  }

  if (placement) {
    middleware.push(autoPlacement(placement));
  } else {
    middleware.push(flip());
  }

  const floating = useFloating({
    placement: 'bottom',
    middleware,
    strategy: strategy as Strategy,
  });

  if (floating.x === null && floating.y === null) {
    floating.x = initialPosition?.x ?? 0;
    floating.y = initialPosition?.y ?? 0;
  }

  const handleMouseDown = () => {
    setPressed(true);
  };

  const handleMouseUp = () => {
    setPressed(false);
  };

  // we need to define a limited movement area to ensure that the user
  // can only drag and drop the element within the visible boundaries
  // of the screen. This helps to prevent the element from being dropped
  // out of the screen preventing access to the drag button again.
  const getBoundedPositions = (container: HTMLElement, event: MouseEvent) => {
    const containerRect = container.getBoundingClientRect();

    const screenWidth = parentEl ? parentEl.clientWidth : window.innerWidth;
    const screenHeight = parentEl ? parentEl.clientHeight : window.innerHeight;

    const maxX = screenWidth - containerRect.width;
    const maxY = screenHeight - containerRect.height;

    return {
      x: Math.max(0, Math.min((floating.x ?? 0) + event.movementX, maxX)),
      y: Math.max(0, Math.min((floating.y ?? 0) + event.movementY, maxY)),
    };
  };

  const handleMouseMove = animationThrottle((event: MouseEvent) => {
    if (!floating.refs.floating.current) {
      return;
    }

    const container = floating.refs.floating.current;
    const pos = getBoundedPositions(container, event);

    floating.x = pos.x;
    floating.y = pos.y;

    container.style.top = `${floating.y}px`;
    container.style.left = `${floating.x}px`;
  });

  const handleWindowResize = debounce(
    () => reorganizeWindow(floating, parentEl),
    100
  );

  useEffect(() => {
    window.addEventListener('resize', handleWindowResize);

    return () => window.removeEventListener('resize', handleWindowResize);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!pressed) {
      return;
    }

    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);

    // eslint-disable-next-line consistent-return
    return () => {
      handleMouseMove.cancel();
      document.removeEventListener('mousemove', handleMouseMove);
      document.removeEventListener('mouseup', handleMouseUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pressed]);

  return {
    pressed,
    position: { x: floating.x, y: floating.y },
    positionReference: floating.refs.setPositionReference,
    containerRef: floating.refs.setFloating,
    dragHandlers: {
      onMouseDown: handleMouseDown,
      onMouseUp: handleMouseUp,
    },
    dragStyle: {
      userSelect: pressed ? 'none' : 'auto',
      cursor: 'grab',
    } as React.CSSProperties,
    containerStyle: {
      position: floating.strategy,
      top: floating.y ?? 0,
      left: floating.x ?? 0,
      // when pressed this window should be on top of all other windows
      // can't use Number.MAX_SAFE_INTEGER as it breaks when using sonar select within
      // the floating window
      ...(pressed && { zIndex: 3 }),
    } as React.CSSProperties,
  };
};

export default useFloatingWindow;
