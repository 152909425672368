import React from 'react';
import PropTypes from 'prop-types';
import { BlockContentShape } from 'entities/block';
import {
  BUTTON,
  IMAGE,
  TEXT,
  VIDEO,
  EMOJI,
  HERO,
  IMAGE_WITH_TEXT,
} from 'lib/block';
import ButtonSettings from './ButtonSettings';
import ImageSettings from './ImageSettings';
import TextSettings from './TextSettings';
import VideoSettings from './VideoSettings';
import EmojiSettings from './EmojiSettings';
import HeroSettings from './HeroSettings';
import ImageWithTextSettings from './ImageWithTextSettings';

export function ContentSettings({ blockProperties, handleUpdate }) {
  const { primitiveBlock, primitiveType, blockType } = blockProperties;

  return (
    <>
      {primitiveType === BUTTON && (
        <ButtonSettings content={primitiveBlock} onChange={handleUpdate} />
      )}

      {primitiveType === IMAGE && (
        <ImageSettings content={primitiveBlock} onChange={handleUpdate} />
      )}

      {/* EMOJI is a primitive but works like a text inside, 
        so we need to do this check */}
      {blockType === EMOJI && (
        <EmojiSettings content={primitiveBlock} onChange={handleUpdate} />
      )}

      {blockType !== EMOJI && primitiveType === TEXT && (
        <TextSettings content={primitiveBlock} onChange={handleUpdate} />
      )}

      {primitiveType === VIDEO && (
        <VideoSettings content={primitiveBlock} onChange={handleUpdate} />
      )}

      {blockType === HERO && (
        <HeroSettings content={primitiveBlock} onChange={handleUpdate} />
      )}

      {blockType === IMAGE_WITH_TEXT && (
        <ImageWithTextSettings
          content={primitiveBlock}
          onChange={handleUpdate}
        />
      )}
    </>
  );
}

ContentSettings.propTypes = {
  blockProperties: PropTypes.shape({
    primitiveBlock: BlockContentShape,
    primitiveType: PropTypes.string,
    blockType: PropTypes.string,
  }),
  handleUpdate: PropTypes.func,
};

export default ContentSettings;
