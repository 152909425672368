import React, { forwardRef, useLayoutEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { EditorShape } from 'entities/step-groups';
import { ContentShape } from 'entities/step-children';
import { Shape as UserPreferencesShape } from 'entities/user-preferences';
import { DIRECTIONS, THEMES, EMBED } from 'lib/user-preferences';
import { localizeContent, DEFAULT_LOCALE } from 'lib/localization';
import useWindowResize from 'hooks/use-window-resize';
import useContentScale from 'hooks/use-content-scale';
import TraitNode from 'components/Editor/TraitNode';
import ContentNode from 'components/Editor/ContentNode';
import { EmbedViewport } from './styled';

const [LIGHT] = THEMES;
const [LTR] = DIRECTIONS;

const Embed = forwardRef(
  (
    { editorPane, editor, experienceId, content, userPreferences, onDrop },
    $ref
  ) => {
    const [height, setHeight] = useState(0);

    const {
      theme = LIGHT,
      embed = EMBED.default,
      direction = LTR,
      locale,
    } = userPreferences ?? {};
    const width = Math.min(Math.max(Number(embed), EMBED.min), EMBED.max);

    const { id: selectedLocaleId } =
      (locale?.experienceId === experienceId && locale) || DEFAULT_LOCALE;

    const embedSize = {
      width,
      height,
    };

    useLayoutEffect(() => {
      if (!$ref?.current) return;
      setHeight($ref.current.offsetHeight);
    }, [$ref]);

    const [scale, setScale] = useContentScale(editorPane, embedSize);
    useWindowResize(setScale);

    return (
      <EmbedViewport
        ref={$ref}
        aria-label="Embed viewport"
        width={width}
        scale={scale}
        dir={direction}
      >
        <TraitNode
          content={content}
          editor={editor}
          theme={theme}
          onDrop={onDrop}
        >
          <ContentNode
            {...localizeContent(content, selectedLocaleId)} // eslint-disable-line @appcues/jsx-props-no-spreading
            theme={theme}
            onDrop={onDrop}
          />
        </TraitNode>
      </EmbedViewport>
    );
  }
);

Embed.propTypes = {
  editor: EditorShape,
  editorPane: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
  experienceId: PropTypes.string,
  content: ContentShape,
  userPreferences: UserPreferencesShape,
  onDrop: PropTypes.func,
};

export default Embed;
