import { v4 as uuid } from 'uuid';

/**
 * Cloneable step fields
 *
 * @constant
 */
const cloneable = new Set(['actions', 'traits', 'type', 'content']);

/**
 * Sanitize step by removing or replacing IDs and remapping actions
 *
 * @param {Step} step - Step child to sanitize for cloning
 * @return {Partial<Step>} Step child with removed or replaced IDs
 */
export default function sanitize(step) {
  // Initialize lookup of previous and new IDs
  const remapped = {};

  /**
   * Replace existing ID with new UUID and cache mapping
   * If a new UUID already exists for the existing ID, use it
   *
   * @param {ContentNode} node - Content node to update ID
   * @return {ContentNode} Content node with updated ID
   */
  const identify = ({ id, ...rest }) => {
    remapped[id] = remapped[id] ?? uuid();
    return { id: remapped[id], ...rest };
  };

  /**
   * Recursively traverse object to filter out fields and/or update IDs
   *
   * @param {(Step|ContentNode)} node - Step or content node
   * @param {string[]} Allowed fields or null to allow all
   * @return {(Step|ContentNode)} Sanitized node
   */
  const traverse = (node, allowed) =>
    Object.entries(node).reduce((acc, [field, value]) => {
      switch (true) {
        // If content node, recursively revisit with updated ID
        case field === 'content':
        case field === 'label':
        case field === 'errorLabel':
          if (value !== null) {
            acc[field] = traverse(identify(value));
          }
          break;

        // Else if array of items, recursively revisit each item with updated ID
        case field === 'items':
        case field === 'options':
          acc[field] = value.map(item => traverse(identify(item)));
          break;

        // If localized variations, need to update IDs in each variant content
        case field === 'variations':
          acc[field] = Object.entries(value).reduce(
            (variationsAcc, [localeId, localizedContent]) => {
              return {
                ...variationsAcc,
                [localeId]: traverse(identify(localizedContent)),
              };
            },
            {}
          );
          break;

        // Else if all fields allowed or field allowable, set value
        case allowed == null:
        case allowed.has(field):
          acc[field] = value;
          break;

        // Otheriwse filter out value
        default:
          break;
      }
      return acc;
    }, {});

  // Traverse step with cloneable fields
  const { actions, ...rest } = traverse(step, cloneable);

  // Iterate through actions and remap IDs using remapping table
  return {
    ...rest,
    actions: Object.entries(actions ?? {}).reduce((acc, [id, action]) => {
      const newID = remapped[id];
      if (newID !== undefined) {
        acc[newID] = action;
      }
      return acc;
    }, {}),
  };
}
