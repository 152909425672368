import { v4 as uuid } from 'uuid';

export const backgroundContentTemplate = () => ({
  config: {
    content: {
      type: 'image',
      id: uuid(),
      imageUrl:
        'https://res.cloudinary.com/dnjrorsut/image/upload/v1662654207/mobile-builder/spacer.gif',
      style: {
        backgroundImage: {
          imageUrl:
            'https://res.cloudinary.com/dnjrorsut/image/upload/v1657809085/mobile-builder/default-image.jpg',
          intrinsicSize: {
            width: 1920,
            height: 1080,
          },
          contentMode: 'fill',
          verticalAlignment: 'center',
          horizontalAlignment: 'center',
        },
      },
    },
  },
});
